import { ReactNode, useRef } from 'react';

import { DocPanelBodyContainer } from './DocPanelBody.styles';
import { QuoteFromBlock } from './QuoteFromBlock';
import { useHovering } from './useHovering';

type Props = {
  children: ReactNode;
  quoteFromBlockEnabled: boolean;
};

export const DocPanelBody = ({
  children, quoteFromBlockEnabled,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isHovering = useHovering();
  return (
    <DocPanelBodyContainer
      ref={containerRef}
      id="marks-container"
      data-hovering={isHovering}
    >
      {children}
      {quoteFromBlockEnabled && (
        <QuoteFromBlock
          containerRef={containerRef}
        />
      )}
    </DocPanelBodyContainer>
  );
};
