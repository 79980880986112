import { Tag } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { useProduct } from 'src/hooks';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { AttributesContainer } from './Profile.styles';

type Props = {
  companyId: string;
};

export const CustomAttributes = ({ companyId }: Props) => {
  const { product } = useProduct();
  const { company } = useCompany(companyId);
  if (!company) return null;
  const attributes = nodeToArray(company.attributes);
  if (!company.customId && attributes.length === 0) return null;
  const customAttributesDefinitions = keyBy(nodeToArray(product?.companyAttributeDefinitions), 'id');
  return (
    <AttributesContainer>
      {company.customId && (
        <TooltipClickToCopy
          title="Custom ID"
          value={company.customId}
        >
          {props => (
            <Tag color="grey" {...props}>
              Custom ID
            </Tag>
          )}
        </TooltipClickToCopy>
      )}

      {attributes.map((attribute) => {
        const definition = customAttributesDefinitions[attribute.definition.id];
        if (!definition || !('id' in definition)) return null;
        const value = (() => {
          if (attribute.value?.__typename === 'AttributeTextValue') {
            return attribute.value.textValue;
          }
          if (attribute.value?.__typename === 'AttributeNumberValue') {
            return attribute.value.numberValue;
          }
          if (attribute.value?.__typename === 'AttributeCheckboxValue' && !!attribute.value.checkboxValue) {
            return definition.name;
          }
          return null;
        })();

        if (!value) return null;

        return (
          <TooltipClickToCopy
            key={attribute.id}
            title={definition.name}
            value={value}
          >
            {props => (
              <Tag color={definition.color} {...props}>
                {value}
              </Tag>
            )}
          </TooltipClickToCopy>
        );
      })}
    </AttributesContainer>
  );
};
