/* eslint-disable import/no-mutable-exports */
import { AddAttributesFn, AddAttributesDefinitionsFn } from './customerAttributes.types';

export let addFakeAttributes: AddAttributesFn | null = null;
export let addFakeAttributesDefinitions: AddAttributesDefinitionsFn | null = null;

if (import.meta.env.VITE_FAKE_CUSTOMER_ATTRIBUTES === 'on') {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  import('./customerAttributes.fake').then((module) => {
    addFakeAttributes = module.addAttributes;
    addFakeAttributesDefinitions = module.addAttributesDefinitions;
  });
}
