import { DoctypeType, MateFragment } from '@cycle-app/graphql-codegen';
import { AddMemberOutlineIcon } from '@cycle-app/ui/icons';
import { ComponentProps, forwardRef, ReactNode } from 'react';
import { Placement } from 'tippy.js';

import DocAction from 'src/components/DocAction/DocAction';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { Layer } from 'src/types/layers.types';
import { assigneeLabel } from 'src/utils/users.util';

import { AssigneeTooltip } from '../AssigneeTooltip';
import {
  DocItemAvatar, DocPanelAvatar, Container, ActionButtonCursor, Name, NoName, StyledTag,
} from './DocAssignee.styles';

type AssigneeContentProps = {
  className?: string;
  assignee?: MateFragment | null;
  context?: 'doc-item' | 'doc-panel';
  forceFocus?: boolean;
  inheritCursor?: boolean;
  isDisabled?: boolean;
  showLabel?: boolean;
  size?: 'S' | 'M' | 'L';
  tooltipPlacement?: Placement;
  onOpenChange?: VoidFunction;
  onClick?: ComponentProps<'div'>['onClick'];
  docTypeType?: DoctypeType;
  icon?: ReactNode;
};

export const AssigneeContent = forwardRef<HTMLDivElement, AssigneeContentProps>(({
  className,
  assignee,
  context,
  forceFocus = false,
  inheritCursor = false,
  isDisabled = false,
  showLabel = false,
  size = 'M',
  tooltipPlacement,
  onOpenChange,
  onClick,
  docTypeType,
  icon,
}, ref) => {
  const isFeedback = docTypeType === DoctypeType.Feedback;

  const docPanelcontent = (
    <StyledTag
      ref={ref}
      onClick={isDisabled ? undefined : onClick}
      icon={assignee
        ? <DocPanelAvatar user={assignee} size={16} />
        : <AddMemberOutlineIcon size={10} />}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      $isEmpty={!assignee}
    >
      {showLabel && !assignee && isDisabled && <NoName>{`No ${assigneeLabel({ isFeedback })}`}</NoName>}
      {showLabel && !assignee && !isDisabled && <NoName>{`Add ${assigneeLabel({ isFeedback })}`}</NoName>}
      {showLabel && !!assignee && <Name>{`${assignee.firstName} ${assignee.lastName}`}</Name>}
    </StyledTag>
  );

  const docItemContent = assignee
    ? (
      <Container
        ref={ref}
        className={className}
        $showAssigneeName={showLabel}
        $forceFocus={forceFocus}
        $isDisabled={isDisabled}
        $context={context}
        $inheritCursor={inheritCursor}
        onClick={onClick}
      >
        <DocItemAvatar
          user={assignee}
          size={16}
          pointer={!isDisabled}
        />
        {showLabel && (
          <Name>
            {`${assignee.firstName} ${assignee.lastName}`}
          </Name>
        )}
      </Container>
    )
    : (
      <ActionButtonCursor
        ref={ref}
        className={className}
        $showAssigneeName={showLabel}
        $isDisabled={isDisabled}
        $context={context}
        onClick={onClick}
      >
        <DocAction
          forceFocus={forceFocus}
          buttonIcon={icon ?? <AddMemberOutlineIcon size={12} />}
          size={size}
          disabled={isDisabled}
          context={context}
        >
          {showLabel && (
            <NoName>
              {isDisabled
                ? `No ${assigneeLabel({ isFeedback })}`
                : `Add ${assigneeLabel({ isFeedback })}`}
            </NoName>
          )}
        </DocAction>
      </ActionButtonCursor>
    );

  const content = context === 'doc-item' ? docItemContent : docPanelcontent;

  if (!tooltipPlacement) {
    return content;
  }

  return (
    <DropdownLayer
      lazy
      placement="top"
      animation={false}
      layer={Layer.DropdownModalZ3}
      delay={[600, 0]}
      withWrapper={false}
      content={assignee && (
        <AssigneeTooltip
          assignee={assignee}
          isReadOnly={isDisabled}
          onUpdate={onOpenChange}
          docTypeType={docTypeType}
          tooltip={
            docTypeType === DoctypeType.Custom
              ? undefined
              : `${assigneeLabel({
                isFeedback,
                plural: true,
                uppercase: true,
              })} are responsible for closing the feedback loop with customers`
          }
        />
      )}
    >
      {content}
    </DropdownLayer>
  );
});
