import {
  ShyScrollbarCss, ActionButton, CompanyLogo, CustomerAvatar, CircleSkeleton, typo, Button, SelectPanel, truncate,
  InsightCard,
} from '@cycle-app/ui';
import { ContextBlock, ShowMore } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';
import { LineStart } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine.styles';
import { ColorTheme } from '@cycle-app/utilities';
import { Tabs as ReachTabs, TabList as ReachTabList, Tab as ReachTab, TabPanels as ReachTabPanels, TabPanel as ReachTabPanel } from '@reach/tabs';
import { darken, lighten } from 'polished';
import styled from 'styled-components';

import DocHierarchyItem from 'src/components/DocHierarchy/DocHierarchyItem/DocHierarchyItem';
import PortalModal from 'src/components/PortalModal/PortalModal';

const PADDING_X = 32;
const GAP_Y = 24;

export const StyledModal = styled(PortalModal)`
  width: 745px;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_Y}px;
  background: ${p => p.theme.colors.docPanel.bg};
  height: 100%;
  overflow: hidden;
`;

export const ProfileHeader = styled.header`
  flex: 1;
  min-height: 70px;
  max-height: 150px;
  background: ${p => (p.theme.isDark ? darken(0.015, ColorTheme.Grey850) : ColorTheme.Grey100)};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 12px;
`;

export const CloseButton = styled(ActionButton)`
  width: 24px;
  height: 24px;
  --size: 13px;
`;

export const ExpandButton = styled(ActionButton)`
  width: 24px;
  height: 24px;
  --size: 16px;
`;

export const ProfileActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ProfileInfos = styled.div`
  margin: 0 ${PADDING_X}px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Summary = styled.div`
  margin: 0 ${PADDING_X}px;
  border: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const SummaryTitle = styled.div`
  ${typo.body500}
  font-size: 13px;
  color: ${p => p.theme.colors.text.disabled};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledCompanyLogo = styled(CompanyLogo).attrs({
  size: 'L',
})`
  margin-top: calc(var(--company-logo-size) / -2 - ${GAP_Y}px);
  width: fit-content;
`;

export const StyledCustomerAvatar = styled(CustomerAvatar).attrs({
  size: 'L',
})`
  margin-top: calc(var(--customer-logo-size) / -2 - ${GAP_Y}px);
  width: fit-content;
`;

const IMAGE_SIZE = 80;

export const ImageSkeleton = styled(CircleSkeleton).attrs({
  width: IMAGE_SIZE,
  height: IMAGE_SIZE,
})`
  background-color: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
  margin-top: ${-IMAGE_SIZE / 2 - GAP_Y}px;
`;

export const ImageContainer = styled.div`
  margin: 0 ${PADDING_X}px;
`;

export const Skeletons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InfosTitle = styled.p`
  ${typo.headerLight}
`;

const SubtitleFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const InfosSubtitle = styled(SubtitleFlex)`
  color: ${p => p.theme.colors.text.secondary};
`;

export const Left = styled(SubtitleFlex)`
  gap: 24px;
  > * + * {
    position: relative;
    :before {
      content: '·';
      width: 0;
      height: 100%;
      position: absolute;
      left: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Right = styled(SubtitleFlex)`
  margin-left: auto;
`;

export const CompanyLink = styled.a`
  ${typo.body500}
  color: ${p => (p.theme.isDark ? ColorTheme.White : ColorTheme.Cycle)};
`;

export const TextButton = styled(Button).attrs({
  variant: 'nospace',
  size: 'M',
})`
  ${typo.body400}
  :active {
    --color: ${p => p.theme.colors.text.primary};
  }
`;

export const ExternalIdsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  > button {
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AttributesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-left: auto;
  margin-top: 4px;
`;

export const BuiltInProperty = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.body500}
  user-select: text;
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const CountLine = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  user-select: text;
`;

export const CountButton = styled.div`
  ${typo.body500}
  --border-color: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
  outline: 1px solid var(--border-color);
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 6px;
  height: 28px;
  cursor: pointer;
  :hover {
    background: var(--border-color);
  }
`;

export const TooltipCountContent = styled.div`
  ${typo.caption500}
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StatusLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Dot = styled.div<{ $color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${p => p.$color};
`;

export const Tabs = styled(ReachTabs)`
  flex: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TabsScrollable = styled.div`
  padding-bottom: 12px;
  overflow-x: auto;
  flex: none;
  :before {
    content: '';
    width: 100%;
    height: 1px;
    background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
    position: absolute;
    top: 40px;
    left: 0;
  }
`;

export const TabList = styled(ReachTabList)`
  padding: 0 ${PADDING_X}px;
  display: flex;
  align-items: center;
  gap: 8px 12px;
`;

export const Tab = styled(ReachTab)<{
  $selected: boolean;
}>`
  flex: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0 8px;
  margin-bottom: 8px;
  ${typo.body500}
  color: ${p => p.theme.colors.text.secondary};
  border-radius: 6px;
  position: relative;
  ${p => p.$selected && `
    color: ${p.theme.colors.text.primary};
    :after {
      content: '';
      width: 100%;
      height: 2px;
      background: ${ColorTheme.Cycle};
      position: absolute;
      left: 0;
      bottom: -9px;
    }
  `};
  :hover {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey100)};
  }
`;

export const TabPanels = styled(ReachTabPanels)`
  ${ShyScrollbarCss};
`;

export const TabPanel = styled(ReachTabPanel)`
  padding: 0 ${PADDING_X}px 64px;
`;

export const FeaturesHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 0 0;
`;

export const QuotesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  padding: 12px 0 16px;
  background: ${p => p.theme.colors.docPanel.bg};
  z-index: 2;
  position: sticky;
  top: 0;
`;

export const QuotesActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CountItems = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const CountItem = styled.div<{ $color: string }>`
  margin: 0 8px;
  padding-left: 15px;
  position: relative;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  flex: none;
  :before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 2px;
    background: ${p => p.$color};
  }
`;

export const CountValue = styled.div`
  ${typo.body500}
  line-height: 1;
`;

export const CountLabel = styled.div`
  margin-bottom: -3px;
  color: ${p => p.theme.colors.text.secondary};
  line-height: 1;
`;

export const QuoteAction = styled(ActionButton).attrs({ size: 'L' })`
  width: 24px;
  height: 24px;
`;

export const StatusPanel = styled(SelectPanel)`
  background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.White)};
  ${LineStart} {
    width: 24px;
    justify-content: center;
    svg {
      color: ${p => p.theme.colors.text.disabled};
    }
  }
`;

export const StatusButton = styled(Button).attrs({ variant: 'light' })`
  --bg: transparent;
  padding: 0 6px;
  flex: none;
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const PeopleTable = styled.table`
  padding-top: 4px;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 8px;
  tr {
    height: 36px;
    td {
      padding: 0 8px;
    }
    :hover td {
      background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey100)};
    }
  }
  td {
    overflow: hidden;
    :nth-of-type(1) {
      width: 30%;
      border-radius: 6px 0 0 6px;
    }
    :nth-of-type(2) {
      width: 40%; padding: 0 16px
    }
    :nth-of-type(3) {
      width: calc(30% - 24px)
    }
    :nth-of-type(4) {
      width: 40px;
      border-radius: 0 6px 6px 0;
    }
  }
`;

export const FeaturesListContainer = styled.div`
  padding-top: 24px;
`;

export const CountsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px 8px;
  flex-wrap: wrap;
`;

export const DomainButton = styled.button`
  padding: 0;
  ${typo.body500}
  color: ${p => p.theme.colors.text.disabled};
  cursor: pointer;
`;

export const QuotesCell = styled.div`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.caption500}
`;

export const PeopleButton = styled.div`
  cursor: pointer;
  line-height: 1.1;
  ${truncate}
`;

export const ListAvatar = styled(CustomerAvatar).attrs({ size: 'S' })`
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
`;

export const QuotesContainer = styled.div`
  > div {
    margin: 0 !important;
  }
`;

export const FeatureDocsItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FeatureDocItem = styled(DocHierarchyItem)`
  height: 42px;
  border-radius: 8px;
  padding: 0 12px;
`;

export const StyledInsightCard = styled(InsightCard)`
  border-radius: 8px;
  border: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey300)};
  background: ${p => p.theme.colors.docPanel.bg};
  transition: none;

  &:hover {
    background: ${p => (p.theme.isDark ? lighten(0.02, ColorTheme.Grey900) : ColorTheme.Grey50)};
  }

  ${ContextBlock} {
    background: transparent;
    padding: 0 0 0 14px;
    .ProseMirror {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
    p {
      white-space: wrap;
    }
  }

  ${ShowMore} {
    bottom: 3px;
    background-color: ${p => p.theme.colors.docPanel.bg};
    :before {
      box-shadow: -20px 0px 15px 0px ${p => p.theme.colors.docPanel.bg};
    }
  }
`;

export const QuoteCardOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 8px;
  row-gap: 12px;
`;

export const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const CustomerName = styled.div`
  ${typo.caption500}
  color: ${p => p.theme.colors.text.primary};
  flex: none;
`;

export const FeedbackAction = styled(ActionButton)`
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const SummaryContainer = styled.div<{ $writing: boolean }>`
  ${ShyScrollbarCss}
  padding: 0 24px 72px;
  height: 100%;

  * {
    user-select: text;
  }

  h2 {
    font-size: 18px;
  }

  ${p => p.$writing && `
    overflow: hidden;
    #cursor {
      display: inline-block;
      width: 2px;
      height: 1.4em;
      position: relative;
      vertical-align: middle;
      left: 3px;
      border-radius: 3px;
      background: linear-gradient(176.66deg, #AE8FFF -6.92%, #00D2F3 48.35%, #4DED8D 98.56%);
    }
  `};
`;

export const SummaryPortalModal = styled(PortalModal)`
  width: 560px;
  height: 350px;
  padding: 24px 0 0;
  overflow: auto;
`;

export const AiWriting = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.body500}
  background: 
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(134.84deg, #AE8FFF 3.13%, #00D2F3 57.65%, #4DED8D 107.19%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  padding: 4px 8px;
  :after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 42px;
    filter: blur(24px);
    z-index: -1;
    ${p => (p.theme.isDark
    ? 'background: linear-gradient(0deg, rgba(34, 34, 34, 0.76) 0%, rgba(34, 34, 34, 0.76) 100%), linear-gradient(150deg, #AE8FFF 20.37%, #00D2F3 61.53%, #4DED8D 98.93%);'
    : 'background: linear-gradient(0deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%), linear-gradient(137deg, #AE8FFF 12.8%, #00D2F3 57.75%, #4DED8D 98.59%);'
  )};
  }
`;

export const SummaryActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 16px;
  > button {
    --bg: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.White)};
  }
`;
