import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, useMemo, ReactNode, ComponentProps } from 'react';

import { AddButton } from 'src/components/DocHierarchy/DocHierarchyGroup/DocHierarchyGroup.styles';
import { InsightDocSearchDropdown } from 'src/components/DocSearchDropdown/InsightDocSearchDropdown';
import { InsightCreateModal } from 'src/components/InsightCreateModal';
import { useDocTypeInsight, useDocV2, useOptimizedBooleanState } from 'src/hooks';
import { Layer } from 'src/types/layers.types';
import { insightName } from 'src/utils/doc.util';
import { getDocTypeNames } from 'src/utils/docType.util';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

type CustomerChildrenInsightsAddProps = {
  customerId?: string;
  onAddClick?: VoidFunction;
};

export const CustomerChildrenInsightsAdd = (props: CustomerChildrenInsightsAddProps) => {
  return (
    <CustomerChildrenInsights {...props}>
      {buttonProps => (
        <AddButton {...buttonProps}>
          <AddIcon />
        </AddButton>
      )}
    </CustomerChildrenInsights>
  );
};

export const CustomerChildrenInsights = ({
  customerId, onAddClick, children,
}: CustomerChildrenInsightsAddProps & {
  children: (props: ComponentProps<typeof AddButton>) => ReactNode;
}) => {
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const { insight } = useDocTypeInsight();
  const [isDocSearchVisible, {
    setFalseCallback: setDocSearchHidden, setTrueCallback: setDocSearchVisible,
  }] = useOptimizedBooleanState(false);
  const {
    doc: parentDoc, isLoading: isParentLoading,
  } = useDocV2(insightDocParentId);
  const possibleDocTypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);
  if (!insight) return null;

  return (
    <InsightCreateModal
      defaultCustomerId={customerId}
      dropdownProps={{
        placement: 'bottom',
      }}
      hide={() => setInsightDocParentId('')}
      isOpen={!!parentDoc?.id}
      isLoading={isParentLoading}
      isCustomerReadonly={!!customerId}
      parentDoc={parentDoc}
      dropdownLayer={Layer.ModalZ2}
      createFeedback
      inheritPropertiesFrom="parent"
    >
      <InsightDocSearchDropdown
        childDoctypeId={insight?.id}
        dropdownProps={{
          placement: 'bottom',
          layer: Layer.DropdownModalZ4,
        }}
        hideSearchDropdown={setDocSearchHidden}
        isSearchDropdownVisible={isDocSearchVisible}
        onAdd={(docId) => {
          setInsightDocParentId(docId);
          setDocSearchHidden();
        }}
        possibleDoctypes={possibleDocTypes}
        placeholder={getDocTypeNames(possibleDocTypes, {
          prefix: 'Link to',
          suffix: '',
        })}
      >
        {children({
          forceFocus: isDocSearchVisible || !!insightDocParentId,
          onClick: () => {
            requestInsightCreate(() => {
              onAddClick?.();
              setDocSearchVisible();
            });
          },
          tooltipPlacement: 'top',
          tooltip: `Add ${insightName()}`,
        })}
      </InsightDocSearchDropdown>
    </InsightCreateModal>
  );
};
