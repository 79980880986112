import { useEffect, useState } from 'react';

/**
 * This hook is used to detect if the user is hovering the app or not
 * If hovering is detected, the app will show the block actions (quote, drag) in the editor margin
 */
export const useHovering = () => {
  const [isHovering, setIsHovering] = useState(true);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const onWheel = () => {
      if (timeoutId) return;
      setIsHovering(false);
      timeoutId = setTimeout(() => {
        timeoutId = null;
      }, 800);
    };

    const onMouseMove = () => {
      if (timeoutId) return;
      setIsHovering(true);
    };

    const setNotHovering = () => {
      setIsHovering(false);
    };

    window.addEventListener('wheel', onWheel);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseleave', setNotHovering);
    document.addEventListener('keyup', setNotHovering);

    // eslint-disable-next-line consistent-return
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      window.removeEventListener('wheel', onWheel);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseleave', setNotHovering);
      document.removeEventListener('keyup', setNotHovering);
    };
  }, []);

  return isHovering;
};
