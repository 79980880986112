import { Skeleton } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { useCustomerDocsCount } from 'src/hooks/api/queries/customers/useCustomerDocsCount';
import { setCustomerTabId } from 'src/reactives/customerCompanyProfile.reactive';
import { insightName } from 'src/utils/doc.util';
import { getDocTypeName } from 'src/utils/docType.util';

import { CountDonutChart } from './CountDonutChart';
import { Skeletons, CountLine, CountButton, CountsContainer } from './Profile.styles';
import { TooltipCount } from './TooltipCount';

type Props = {
  customerId: string;
};

export const DocsCountCustomer = ({ customerId }: Props) => {
  const { docsCount } = useCustomerDocsCount(customerId);

  if (!docsCount) {
    return (
      <Skeletons>
        <Skeleton height={16} width={300} />
        <Skeleton height={16} width={300} />
      </Skeletons>
    );
  }

  const quotesCount = sumBy(docsCount, 'totalInsights');

  if (quotesCount === 0) {
    return (
      <CountLine>
        No quotes were collected
      </CountLine>
    );
  }

  const sortedDocTypes = sortBy(docsCount, 'doctype.name');

  return (
    <CountsContainer>
      <CountLine>
        <CountButton
          onClick={() => setCustomerTabId('quote')}
        >
          <QuoteIcon size={14} />
          {`${quotesCount} ${insightName({ plural: quotesCount > 1 })}`}
        </CountButton>
        {quotesCount === 1
          ? 'was collected and linked to'
          : 'were collected and linked to'}
      </CountLine>

      <CountLine>
        {sortedDocTypes.map(count => {
          const { doctype } = count;
          return (doctype ? (
            <TooltipCount key={doctype.id} count={count}>
              <CountButton
                onClick={() => setCustomerTabId(doctype.id)}
              >
                <DocTypeIcon doctype={doctype} size={14} />
                {`${count.total} ${getDocTypeName(doctype, true).toLowerCase()}`}
                <CountDonutChart count={count} />
              </CountButton>
            </TooltipCount>
          ) : (
            <CountButton key="unlinked">
              {`${count.toLink} unlinked`}
            </CountButton>
          ));
        })}
      </CountLine>
    </CountsContainer>
  );
};
