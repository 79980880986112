import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DollarIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9 5.625V4.875M9 12.375V13.125M10.6241 6.75C10.3 6.30165 9.69405 6 9 6H8.79165C7.87118 6 7.125 6.59695 7.125 7.33333V7.39058C7.125 7.9173 7.49699 8.3988 8.0859 8.63437L9.9141 9.36563C10.503 9.6012 10.875 10.0827 10.875 10.6094C10.875 11.3774 10.0968 12 9.1368 12H9C8.30595 12 7.70003 11.6984 7.37585 11.25M15.75 9C15.75 12.728 12.728 15.75 9 15.75C5.27208 15.75 2.25 12.728 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.728 2.25 15.75 5.27208 15.75 9Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
