import { Flex } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal';
import { FeedbackViewSettingsModals } from 'src/components/FeedbackViewSettings/FeedbackViewSettingsModals';
import { SlackInstallCard } from 'src/components/SlackInstallCard';
import { ViewBreadcrumb } from 'src/components/ViewBreadcrumb';
import { ViewSettings, ViewActions } from 'src/components/ViewHeader';
import { AddViewButton } from 'src/components/ViewHeader/AddViewButton';
import { ViewsList } from 'src/components/ViewsList';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useInboxSection } from 'src/hooks/inbox';
import { useFeedbackTour } from 'src/hooks/useFeedbackTour';
import { useParentPage } from 'src/hooks/usePageId';
import { useBoardId, useBoardSlug } from 'src/hooks/usePathParams';
import { useGetPermission, useIsMobile } from 'src/reactives';
import { useBoardConfigAnalytics } from 'src/reactives/boardConfigAnalytics.reactive';
import { useGetLastView } from 'src/reactives/lastView.reactive';

import { InsightViewAnalyticsSettingsModal } from '../../InsightViewSettings/InsightViewAnalyticsSettingsModal';
import { InboxContainer, Title, Action } from './Inbox.styles';

export const Inbox: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const boardSlug = useBoardSlug();
  const parent = useParentPage();
  if (parent !== 'inbox') return null;
  if (!boardSlug) return <InboxViewsPage />;
  return <InboxViewPage>{children}</InboxViewPage>;
};

const InboxViewsPage = () => {
  const { open: openFeedbackTour } = useFeedbackTour();
  const isMobile = useIsMobile();
  const {
    isPermissionInit, canCreateView,
  } = useGetPermission();
  return (
    <>
      <InboxContainer $variant="primary">
        <header>
          <div>
            <Title>
              Feedback
            </Title>

            <Action
              tooltip="Learn more"
              tooltipPlacement="bottom"
              onClick={openFeedbackTour}
            >
              <InfoIconOutline size={14} />
            </Action>
          </div>

          <div>
            {!isMobile && isPermissionInit && canCreateView && (
              <AddViewButton sectionParent="inbox" />
            )}
          </div>
        </header>

        <ViewsList sectionParent="inbox" />
      </InboxContainer>

      <BoardConfigContextProvider>
        <CreateDocModal />
      </BoardConfigContextProvider>
    </>
  );
};

const InboxViewPage = ({ children }: { children: ReactNode }) => {
  const { isNewInbox } = useGetLastView();
  const { isPermissionInit } = useGetPermission();
  const isMobile = useIsMobile();
  return (
    <BoardConfigContextProvider>
      <InboxContainer $variant={isNewInbox ? 'primary' : 'secondary'}>
        <header>
          <ViewBreadcrumb sectionParent="inbox" />

          <div>
            {!isMobile && isPermissionInit && (
              <Flex $gap={4}>
                <ViewSettings />
                <ViewActions />
              </Flex>
            )}
          </div>
        </header>

        <SlackInstallCard />

        {children}
        <SettingsModals />
        <SettingsAnalyticsModal />
      </InboxContainer>
    </BoardConfigContextProvider>
  );
};

export const SettingsModals = () => {
  const { boards } = useInboxSection();
  const boardId = useBoardId();
  const boardWithConfig = boards.find(b => b.id === boardId);
  if (!boardWithConfig?.draftBoardConfig) return null;
  return (
    <FeedbackViewSettingsModals
      board={boardWithConfig}
      draftBoardConfigId={boardWithConfig.draftBoardConfig?.id || ''}
    />
  );
};

export const SettingsAnalyticsModal = () => {
  const { boards } = useInboxSection();
  const boardId = useBoardId();
  const board = boards.find(b => b.id === boardId);
  const isOpen = useBoardConfigAnalytics();
  return (board && isOpen && (
    <InsightViewAnalyticsSettingsModal
      board={board}
    />
  )) || null;
};
