import { ActionButton } from '@cycle-app/ui';
import { Container as ActionButtonContainer } from '@cycle-app/ui/components/Buttons/ActionButton/ActionButton.styles';
import { ColorTheme } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { QUOTE_BUTTON_WIDTH } from 'src/constants/editor.constants';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const QuoteContainer = styled(motion.div)<{
  $collapsed: boolean;
}>`
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden;

  ${p => p.$collapsed && `
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${QUOTE_BUTTON_WIDTH + 14}px;
  `}
`;

export const QuoteButton = styled(ActionButton)`
  padding: 0;
  width: 24px;
  height: 24px;
`;

export const FeatureCardContainer = styled.div<{
  $active: boolean;
}>`
  margin-bottom: 16px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.docPanel.border};
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  --bgActive: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey100)};

  :hover {
    background: var(--bgActive);
  }

  ${p => p.$active && `
    background: var(--bgActive);
  `}

  ${ActionButtonContainer} {
    --bg: transparent;
    --bgHover: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
    --bgActive: ${p => (p.theme.isDark ? ColorTheme.Grey750 : ColorTheme.Grey300)};
    --color: ${p => (p.theme.isDark ? ColorTheme.Grey750 : ColorTheme.Grey400)};  
    --colorHover: ${p => (p.theme.isDark ? ColorTheme.Grey500 : ColorTheme.Grey600)};
    &[data-counter="true"] {
      --color: ${p => (p.theme.isDark ? ColorTheme.Grey600 : ColorTheme.Grey500)};
    }
  }
`;

export const QuoteCardContainer = styled(FeatureCardContainer)`
  cursor: default;
  .ProseMirror {
    padding: 0 !important;
    p {
      line-height: 24px;
      color: ${p => p.theme.colors.text.secondary};
    }
  }
`;

export const Title = styled.div<{ $empty: boolean }>`
  overflow-wrap: break-word;
  ${p => p.$empty && `
    color: ${p.theme.colors.text.disabled};
  `}
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const QuoteCardHeader = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
`;
