/* eslint-disable no-nested-ternary */
import { Badge, Spinner } from '@cycle-app/ui';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';

import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import { useCompanyDocsCount } from 'src/hooks/api/queries/customers/useCompanyDocsCount';
import {
  setCompanyTabId,
  setCompanyStatusCategory,
  useCompanyTabId,
  useCompanyStatusCategory,
} from 'src/reactives/customerCompanyProfile.reactive';
import { useInsightParentDocTypes } from 'src/reactives/docTypes.reactive';

import { FeaturesCounters } from './FeaturesCounters';
import { FeaturesListCompany } from './FeaturesListCompany';
import { PeopleList } from './PeopleList';
import {
  Tabs, TabsScrollable, TabList, Tab, TabPanels, TabPanel, FeaturesHeader,
} from './Profile.styles';
import { QuotesListCompany } from './QuotesListCompany';
import { StatusCategorySelect } from './StatusCategorySelect';

type Props = {
  companyId: string;
};

export const TabsCompany = ({ companyId }: Props) => {
  const {
    docsCount, isLoaded: isCountLoaded,
  } = useCompanyDocsCount(companyId);

  const {
    company, isLoading: isLoadingCompany,
  } = useCompany(companyId);

  const tabId = useCompanyTabId();
  const statusCategory = useCompanyStatusCategory();

  const docTypes = useInsightParentDocTypes();
  if (!docTypes.length) return null;

  const quotesCount = sumBy(docsCount, 'totalInsights');
  const sortedDocTypes = sortBy(docTypes, 'name');

  const tabIds = ['people', 'quotes', ...sortedDocTypes.map(docType => docType.id)];
  const tabIndex = tabIds.indexOf(tabId);

  return (
    <Tabs
      index={tabIndex < 0 ? 0 : tabIndex}
      onChange={index => setCompanyTabId(tabIds[index] ?? 'people')}
    >
      {({ selectedIndex }) => (
        <>
          <TabsScrollable>
            <TabList>
              <Tab
                $selected={selectedIndex === 0}
              >
                People
                {isLoadingCompany ? <Spinner /> : <Badge>{company?.countCustomers ?? 0}</Badge>}
              </Tab>

              <Tab
                $selected={selectedIndex === 1}
              >
                Quotes
                {!docsCount ? <Spinner /> : <Badge>{quotesCount}</Badge>}
              </Tab>

              {sortedDocTypes.map((docType, index) => {
                const count = docsCount?.find(c => c.doctype?.id === docType.id)?.total ?? 0;
                return (
                  <Tab
                    key={docType.id}
                    $selected={selectedIndex === index + 2}
                  >
                    {docType.name}
                    {!docsCount ? <Spinner /> : <Badge>{count}</Badge>}
                  </Tab>
                );
              })}
            </TabList>
          </TabsScrollable>

          <TabPanels>
            <TabPanel>
              {selectedIndex === 0 && <PeopleList companyId={companyId} />}
            </TabPanel>
            <TabPanel>
              {selectedIndex === 1 && <QuotesListCompany companyId={companyId} />}
            </TabPanel>
            {sortedDocTypes.map((docType, index) => {
              const count = docsCount?.find(c => c.doctype?.id === docType.id);
              return (
                <TabPanel key={docType.id}>
                  {selectedIndex === index + 2 && (
                    <>
                      <FeaturesHeader>
                        <StatusCategorySelect
                          value={statusCategory}
                          onChange={setCompanyStatusCategory}
                        />
                        {isCountLoaded && (<FeaturesCounters count={count} />)}
                      </FeaturesHeader>

                      <FeaturesListCompany
                        companyId={companyId}
                        doctypeId={docType.id}
                      />
                    </>
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </>
      )}
    </Tabs>
  );
};
