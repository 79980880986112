import { FetchResult } from '@apollo/client';
import { UpdateDocCoverMutation } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import { DocPanelBaseProps } from 'src/app/Main/Board/DocPanel/DocPanel.types';
// import { setDocViewCoverInput, openDocViewCoverInputFile } from 'src/reactives';

import { useUpdateDocTitle } from './api/mutations/updateDocHooks';
import { useFullDoc } from './api/useDoc';
import useDocCoverDropzone from './doc/useCoverDropzone';

interface DocPanelProps extends DocPanelBaseProps {
  getDropzonePropsTopBar: () => DropzoneRootProps;
  updateDocCover: (cover: File) => Promise<FetchResult<UpdateDocCoverMutation>>;
  setDocViewCoverInput: (element: HTMLInputElement) => void;
  isLoading: boolean;
  coverInputElement: HTMLInputElement | null;
}

let coverInputElement: HTMLInputElement | null = null;

export const useDocPanelProps = (draft?: boolean, id?: string): DocPanelProps => {
  const {
    doc, loading,
  } = useFullDoc({
    draft,
    docId: id,
  });
  const docId = doc?.id ?? '';

  const { updateDocTitleDebounced } = useUpdateDocTitle();
  const onTitleUpdated = useCallback(async (updatedTitle: string) => {
    if (!docId) return;
    await updateDocTitleDebounced({
      docId,
      title: updatedTitle,
    });
  }, [docId, updateDocTitleDebounced]);

  const {
    getRootProps: getDropzonePropsTopBar,
    isDragActive: isDragActiveFromTopBar,
    isUploadingCover: isUploadingCoverFromTopBar,
    updateDocCover,
  } = useDocCoverDropzone({ docId });
  const {
    getRootProps: getDropzonePropsCover,
    isDragActive: isDragActiveFromCover,
    isUploadingCover: isUploadingCoverFromCover,
  } = useDocCoverDropzone({ docId });

  const isDraggingCover = isDragActiveFromTopBar || isDragActiveFromCover;
  const isUploadingCover = isUploadingCoverFromTopBar || isUploadingCoverFromCover;

  const setDocViewCoverInput = (el: HTMLInputElement | null) => {
    coverInputElement = el;
  };
  const openDocViewCoverInputFile = () => coverInputElement?.click();

  return {
    doc,
    onTitleUpdated,
    getDropzonePropsTopBar,
    getDropzonePropsCover,
    isDraggingCover,
    isLoading: loading,
    isUploadingCover,
    onOpenCoverInputFile: openDocViewCoverInputFile,
    updateDocCover,
    setDocViewCoverInput,
    mode: draft ? 'new-doc' : 'edit-doc',
    coverInputElement,
  };
};
