import { DocFullFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import DocOptions from 'src/components/DocOptions/DocOptions';
import { useVerifyQuotesOpen } from 'src/reactives/docRightPanel.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import DocPanelRealtime from '../DocPanelRealtime/DocPanelRealtime';
import {
  RightSide,
  Container,
  LeftActions,
  StyledActionButton,
  Separator,
  BreadCrumb,
  StyledDocParentDropdown,
  DocTitle,
  RightActions,
} from './DocPanelHeader.styles';
import { ToggleRightPanel } from './ToggleRightPanel';

interface Props {
  className?: string;
  doc: DocFullFragment;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
  onDeleteDoc: VoidFunction;
  onOpenCoverInputFile: VoidFunction;
  prevNextButtons?: ReactNode;
  processButton?: ReactNode;
  disabled?: boolean;
}

export const DocPanelHeader: FC<Props> = ({
  className,
  doc,
  onClose,
  onExpand,
  onDeleteDoc,
  onOpenCoverInputFile,
  prevNextButtons,
  processButton,
  disabled = false,
}) => {
  const canHaveParent = (doc.doctype.parents?.edges ?? []).length > 0;
  const isMobile = useIsMobile();
  const isVerifyQuotesOpen = useVerifyQuotesOpen();

  if (isMobile) return null;

  return (
    <Container className={className} $disabled={disabled}>
      {(onClose || onExpand) && (
        <LeftActions>
          {onClose && (
            <StyledActionButton
              size="L"
              onClick={onClose}
              tooltip="Close"
              tooltipPlacement="bottom"
            >
              <CloseIcon />
            </StyledActionButton>
          )}
          {onExpand && (
            <StyledActionButton
              size="L"
              onClick={onExpand}
              tooltip="Open full page"
              tooltipPlacement="bottom"
            >
              <ExpandIcon />
            </StyledActionButton>
          )}
          <Separator />
        </LeftActions>
      )}

      {prevNextButtons}

      <BreadCrumb>
        <span>
          {canHaveParent && (
            <>
              <StyledDocParentDropdown
                docId={doc.id}
                docTypeId={doc.doctype.id}
                size="M"
                showIcon={false}
                minimal
                showLinearAutoCreate
              />
              <span>/</span>
            </>
          )}
          <Tooltip
            content={doc.title}
            placement="bottom"
            withPortal
            displayFullTitle
          >
            <DocTitle>{doc.title}</DocTitle>
          </Tooltip>
        </span>
      </BreadCrumb>

      <RightSide>
        <DocPanelRealtime />
        {processButton}
        <RightActions>
          <DocOptions
            doc={doc}
            context="doc-panel"
            onAddCoverClicked={onOpenCoverInputFile}
            onDeleteDoc={onDeleteDoc}
            buttonSize="L"
          />
          {!isVerifyQuotesOpen && <ToggleRightPanel />}
        </RightActions>
      </RightSide>
    </Container>
  );
};

export default DocPanelHeader;
