import { CompanyFragment } from '@cycle-app/graphql-codegen/generated';
import { CellText, CompanyLogo, Tooltip } from '@cycle-app/ui';
import { ReactNode, FC } from 'react';

import { StyledCellContent } from './CustomerTableCompanyCell.styles';

type Props = {
  company: CompanyFragment;
  name: ReactNode;
  onClick?: VoidFunction;
};

export const CustomerTableCompanyCell: FC<React.PropsWithChildren<Props>> = ({
  name, onClick, company,
}) => (
  <Tooltip
    content="Open company"
    placement="top"
    withWrapper={false}
    withPortal
  >
    <StyledCellContent onClick={onClick} $limitSize>
      <CompanyLogo size="S" company={company} />
      <CellText>
        {name}
      </CellText>
    </StyledCellContent>
  </Tooltip>
);
