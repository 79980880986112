import { Flex } from '@cycle-app/ui';

import { useProduct } from 'src/hooks';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';
import { useProductStatus } from 'src/reactives/product.reactive';
import { setUpgradeModal } from 'src/reactives/upgradeModal.reactive';

import {
  EmptyState,
  PreviewContainer,
  Title,
  Paragraph,
  EnableButton,
  InsightsProgressBar,
  InsightsCount,
  Link,
} from './AnalysesPage.styles';

export const AnalyseEmptyState = () => {
  const productAddOn = useProductAddOnRequest();
  const { productStatus } = useProductStatus();
  const { product } = useProduct();
  const insightsCount = product?.nbActiveInsights || 0;
  return (
    <EmptyState>
      <PreviewContainer>
        <Flex $column $gap={12}>
          <Title>
            Customer voice dashboard
          </Title>
          <Paragraph>
            Visualize your customer quotes in a whole new way with a powerful and interactive dashboard.
          </Paragraph>
        </Flex>

        {(() => {
          if (productStatus === 'trial') {
            return (
              <Flex $column $gap={16}>
                <EnableButton
                  onClick={() => setUpgradeModal({ isOpened: true })}
                >
                  Upgrade
                </EnableButton>
                <DemoLink />
              </Flex>
            );
          }

          if (insightsCount < 25) {
            return (
              <Flex $column $gap={10}>
                <p>
                  You need at least 25 insights to enable your dashboard
                </p>
                <Flex $gap={14}>
                  <InsightsProgressBar
                    $percentage={insightsCount * 4}
                    $colorType="default"
                  />
                  <InsightsCount>
                    {`${insightsCount}/25`}
                  </InsightsCount>
                </Flex>
              </Flex>
            );
          }

          return (
            <Flex $column $gap={16}>
              <EnableButton
                onClick={() => productAddOn.request('ANALYSES')}
                isLoading={productAddOn.isRequesting}
              >
                Request access
              </EnableButton>
              <DemoLink />
            </Flex>
          );
        })()}
      </PreviewContainer>
    </EmptyState>
  );
};

const DemoLink = () => (
  <Link
    href="https://app.arcade.software/share/MTIhqzQcp4VlcacWk02a"
    target="_blank"
  >
    View demo
  </Link>
);
