import { DocTargetFragment } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { MoreHorizIcon } from '@cycle-app/ui/icons';
import { forwardRef } from 'react';

import { DocOptionsMenu } from 'src/components/DocOptions/DocOptionsMenu';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { CommentEditor } from 'src/components/Editor';

import { QuoteCardAttributes } from './QuoteCardAttributes';
import { QuoteCardContainer, QuoteCardHeader } from './QuoteCards.styles';

type Props = {
  active: boolean;
  quote: DocTargetFragment;
};

export const QuoteCard = forwardRef<HTMLDivElement, Props>(({
  active, quote,
}, ref) => {
  return (
    <QuoteCardContainer
      ref={ref}
      $active={active}
    >
      <QuoteCardHeader>
        <div>
          {quote.doc && (
            <QuoteCardAttributes
              docId={quote.doc.id}
            />
          )}
        </div>
        <ToggleDropdown
          withPortal
          button={props => (
            <ActionButton
              onClick={props.onClick}
              ref={props.ref}
              forceFocus={props['data-active']}
            >
              <MoreHorizIcon />
            </ActionButton>
          )}
          content={props => quote.doc && (
            <DocOptionsMenu
              doc={quote.doc}
              hide={props.hide}
              showUnlink={false}
              showActivity={false}
              keepDropdownOnSelect
            />
          )}
        />
      </QuoteCardHeader>

      <CommentEditor
        isReadOnly
        content={quote.content ?? ''}
      />
    </QuoteCardContainer>
  );
});
