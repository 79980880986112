import { Skeleton, InfiniteScroll } from '@cycle-app/ui';

import { useCustomerDocs } from 'src/hooks/api/queries/customers/useCustomerDocs';
import { useCustomerStatusCategory } from 'src/reactives/customerCompanyProfile.reactive';

import { Skeletons, FeaturesListContainer, FeatureDocsItems, FeatureDocItem } from './Profile.styles';

type Props = {
  customerId: string;
  doctypeId: string;
};

export const FeaturesListCustomer = ({
  customerId, doctypeId,
}: Props) => {
  const statusCategory = useCustomerStatusCategory();

  const {
    docs,
    loadMore,
    pageInfo,
    isLoading,
    isLoaded,
    isPaginationLoading,
  } = useCustomerDocs({
    id: customerId,
    doctypeId,
    statusCategory,
  });

  return (
    <FeaturesListContainer>
      <InfiniteScroll
        loadMore={() => {
          if (!pageInfo?.hasNextPage || !pageInfo?.endCursor) return;
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          loadMore(pageInfo.endCursor);
        }}
        hasMoreData={!!pageInfo?.hasNextPage}
        isLoading={isPaginationLoading}
      >
        <FeatureDocsItems>
          {docs.map(doc => (
            <FeatureDocItem
              key={doc.id}
              doc={doc}
              showStatus
              showDoctype
              showQuotes
              isStatusEditable
            />
          ))}

          {((!isLoaded && isLoading) || isPaginationLoading) && (
            <Skeletons>
              <Skeleton height={16} />
              <Skeleton height={16} />
              <Skeleton height={16} />
              <Skeleton height={16} />
            </Skeletons>
          )}
        </FeatureDocsItems>
      </InfiniteScroll>
    </FeaturesListContainer>
  );
};
