import { NavigationItemProps } from '@cycle-app/ui';
import {
  CaretIcon, WheelIcon, InboxIcon, BulbIcon, RoadmapIcon, CustomerIconOutline,
  UsersIcon, TagIcon, ShapesIcon, BagIcon, CodeBorderIcon,
  AiOutlineIcon, ReleaseIcon,
} from '@cycle-app/ui/icons';
import { memo } from 'react';
import { Switch } from 'react-router-dom';

import { PageId, SettingsPageId } from 'src/constants/routing.constant';
import { useFeatureFlag, useRouteAccess, useProduct } from 'src/hooks';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { usePageId } from 'src/hooks/usePageId';
import { useGetLastView } from 'src/reactives/lastView.reactive';
import { getUrl, isSettingsPath } from 'src/utils/routing.utils';

import {
  Container,
  SidebarHeader,
  Title,
  SettingsNavigationSection,
  SettingsNavigationItem,
  Back,
  Logo,
} from './SettingsSidebar.styles';

const SettingsSidebar = memo(() => {
  const { product } = useProduct();
  const { settingsFromUrl } = useGetLastView();
  const backToBoardUrl = settingsFromUrl && !isSettingsPath(settingsFromUrl) ? settingsFromUrl : getUrl(PageId.Main);
  const { isEnabled: isSettingsApiEnabled } = useFeatureFlag('settings-api');
  const hasBillingAccess = useRouteAccess(PageId.SettingsBilling);

  return (
    <Switch>
      <Container>
        <SidebarHeader>
          <Back to={backToBoardUrl}>
            <CaretIcon direction="left" />
            {product?.logo && <Logo $bgSrc={product?.logo?.url} />}
            <Title>Settings</Title>
          </Back>
        </SidebarHeader>
        <SettingsNavigationSection>
          <NavItem
            icon={<WheelIcon size={18} />}
            label="General"
            pageId={PageId.Settings}
            getIsActive={pageId => pageId === PageId.Settings}
          />
          <NavItem
            icon={<AiOutlineIcon size={19} />}
            label="Cycle AI"
            pageId={PageId.SettingsAi}
            getIsActive={pageId => pageId === PageId.SettingsAi}
          />
          <NavItem
            icon={<UsersIcon size={19} />}
            label="Members"
            pageId={PageId.SettingsUsers}
            getIsActive={pageId => pageId === PageId.SettingsUsers}
          />
          <NavItem
            icon={<InboxIcon size={17} />}
            label="Feedback"
            pageId={PageId.SettingsFeedback}
            getIsActive={pageId => pageId === PageId.SettingsFeedback}
          />
          <NavItem
            icon={<BulbIcon size={19} />}
            label="Insights"
            pageId={PageId.SettingsInsights}
            getIsActive={pageId => pageId === PageId.SettingsInsights}
          />
          <NavItem
            icon={<RoadmapIcon size={17} />}
            label="Roadmaps"
            pageId={PageId.SettingsRoadmaps}
            getIsActive={pageId => pageId === PageId.SettingsRoadmaps || pageId === PageId.SettingsRoadmapsDocType}
          />
          <NavItem
            icon={<ReleaseIcon size={17} />}
            label="Releases"
            pageId={PageId.SettingsReleases}
            getIsActive={pageId => pageId === PageId.SettingsReleases}
          />
          <NavItem
            icon={<CustomerIconOutline size={18} />}
            label="Customers"
            pageId={PageId.SettingsCustomers}
            getIsActive={pageId => [
              PageId.SettingsCustomers,
              PageId.SettingsCustomer,
              PageId.SettingsCompanies,
              PageId.SettingsCompany].includes(pageId)}
          />
          <NavItem
            icon={<TagIcon size={18} />}
            label="Properties"
            pageId={PageId.SettingsAttributes}
            getIsActive={pageId => pageId === PageId.SettingsAttributes}
          />
          <NavItem
            icon={<ShapesIcon size={19} />}
            label="Integrations"
            pageId={PageId.SettingsIntegrations}
            getIsActive={pageId => pageId === PageId.SettingsIntegrations}
          />
          {isSettingsApiEnabled && (
            <NavItem
              icon={<CodeBorderIcon size={14} />}
              label="API"
              pageId={PageId.SettingsAPI}
              getIsActive={pageId => pageId === PageId.SettingsAPI}
            />
          )}
          {hasBillingAccess && (
            <NavItem
              icon={<BagIcon size={18} />}
              label="Billing"
              pageId={PageId.SettingsBilling}
              getIsActive={pageId => pageId === PageId.SettingsBilling}
            />
          )}
        </SettingsNavigationSection>
      </Container>
    </Switch>
  );
});

export default SettingsSidebar;

type NavItemProps = Omit<NavigationItemProps, 'isActive'> & {
  pageId: SettingsPageId;
  getIsActive: (pageId: PageId) => boolean;
};

const NavItem = ({
  pageId, getIsActive, ...props
}: NavItemProps) => {
  const isActive = usePageId(getIsActive);
  const { navigate } = useNavigateToSettings();
  return (
    <SettingsNavigationItem
      {...props}
      linkTo={getUrl(pageId)}
      isActive={isActive}
      onClick={(e) => {
        e.preventDefault();
        navigate(pageId, undefined, undefined, e.metaKey);
      }}
    />
  );
};
