import { MateFragment } from '@cycle-app/graphql-codegen';
import { EyeIcon } from '@cycle-app/ui/icons';
import { getDocSlug } from '@cycle-app/utilities';
import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useBoard } from 'src/hooks/api/useBoard';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useDoc } from 'src/hooks/api/useDoc';
import { useUrl } from 'src/hooks/useUrl';
import { getBoardSlug } from 'src/utils/slug.util';

import { UserTooltip, User, TooltipLink, StyledAvatar } from './RealtimeUsers.styles';
import { UserLocation } from './UserLocation';

export interface Props {
  user: MateFragment;
  showLocation?: boolean;
}

const RealtimeUser: FC<Props> = ({
  user,
  showLocation,
}) => {
  const getUrl = useUrl();
  const sections = useBoardSections();
  const board = useBoard(user._boardId, !user._boardId);
  const { doc } = useDoc(user._docId, !user._docId);

  let locationUrl = doc
    ? getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) })
    : getUrl(PageId.Board, { boardSlug: board && Object.keys(board).length ? getBoardSlug(board) : '' });

  if (board && Object.keys(board).length) {
    const isInboxView = sections.inbox.some(view => view.id === board.id);
    const isInsightView = sections.insights.some(view => view.id === board.id);
    const isRoadmapView = sections.roadmaps.some(view => view.id === board.id);
    if (isInboxView) locationUrl = getUrl(PageId.InboxView, { boardSlug: getBoardSlug(board) });
    if (isInsightView) locationUrl = getUrl(PageId.InsightView, { boardSlug: getBoardSlug(board) });
    if (isRoadmapView) locationUrl = getUrl(PageId.RoadmapView, { boardSlug: getBoardSlug(board) });
  }

  const displayedName = `${user.firstName} ${user.lastName}`;

  return (
    <User key={user.id}>
      <UserTooltip
        $color={user?.color}
        title={showLocation ? displayedName : undefined}
        content={showLocation ? (
          <TooltipLink
            to={locationUrl}
            title={doc?.title || board?.name}
          >
            <EyeIcon />
            <span>
              Viewing
              {' '}
              <UserLocation user={user} />
            </span>
          </TooltipLink>
        ) : displayedName}
        placement="bottom"
        controlled={showLocation}
        interactive={showLocation}
        withPortal
      >
        <StyledAvatar
          key={user.id}
          user={user}
          pointer
        />
      </UserTooltip>
    </User>
  );
};

export default RealtimeUser;
