import { SelectLine } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

export const StyledMenuOptionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledMenuOptionBullet = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${Color.Yellow500};
  display: inline-block;
`;

export const StyledMenuOptionFooter = styled(SelectLine)`
  margin: 0 -12px;
  padding: 0 16px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledCalendarIconWrapper = styled.div`
  margin: 0 2px;
`;
