import { NuancePicker, SelectOption } from '@cycle-app/ui';
import { TrashIcon, ReorderIcon } from '@cycle-app/ui/icons';
import { ComponentProps, FC, useMemo } from 'react';
import { CSSProperties } from 'styled-components';

import { AttributeOptionsDropdown } from 'src/components/AttributeOptionsDropdown';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import DoctypesRelationsManager from './DoctypesRelationsManager';
import { SettingsAttributeEditModal } from './SettingsAttributeEditModal';
import {
  GrowingCell,
  ColorCell,
  ReorderButton,
  ReorderCell,
  ActionsCell,
  Doctype,
  RowContainer,
  NameButton,
  ColorButton,
} from './SettingsAttributes.styles';
import { useDeleteAttributeModal } from './useDeleteAttributeModal';

export interface SettingsAttributesLineProps {
  attribute: AttributeDefinitionsNode;
  doctypesOptions: SelectOption[];
  isDragOverlay?: boolean;
  rowProps?: {
    ref: (node: HTMLElement | null) => void;
    style: CSSProperties;
    asPlaceholder: boolean;
  };
  buttonHandleProps?: ComponentProps<typeof ReorderButton>;
  isDeleteDisabled?: boolean;
}

export const SettingsAttributesLine: FC<React.PropsWithChildren<SettingsAttributesLineProps>> = ({
  attribute,
  doctypesOptions,
  isDragOverlay = false,
  rowProps,
  buttonHandleProps,
  isDeleteDisabled = false,
}) => {
  const [isEditionModalOpened, { toggleCallback: toggleEditionModal }] = useOptimizedBooleanState(false);

  const {
    openDeleteAttributeModal, deleteAttributeModal,
  } = useDeleteAttributeModal(attribute);

  const actionOptions = useMemo(() => [
    ...isDeleteDisabled ? [] : [{
      value: 'delete',
      label: 'Delete',
      variant: 'danger' as const,
      icon: <TrashIcon />,
      onSelect: openDeleteAttributeModal,
    }],
  ], [openDeleteAttributeModal, isDeleteDisabled]);

  return (
    <RowContainer isDragOverlay={isDragOverlay} {...rowProps}>
      <ReorderCell>
        <ReorderButton {...buttonHandleProps}>
          <ReorderIcon />
        </ReorderButton>
      </ReorderCell>

      <ColorCell>
        <ColorButton variant="nospace" type="button" onClick={toggleEditionModal}>
          <NuancePicker
            color={attribute.color}
            placement="bottom-start"
            disabled
          />
        </ColorButton>
      </ColorCell>

      <GrowingCell>
        <NameButton type="button" size={14} onClick={toggleEditionModal}>{attribute.name}</NameButton>
      </GrowingCell>

      <GrowingCell>
        {attribute.__typename && (
          <Doctype>
            {getCustomAttributeTypeData(attribute.__typename).icon}
            {getCustomAttributeTypeData(attribute.__typename).label}
          </Doctype>
        )}
      </GrowingCell>

      <GrowingCell>
        {'values' in attribute && (
          <AttributeOptionsDropdown attribute={attribute} placement="bottom-start" />
        )}
      </GrowingCell>

      <GrowingCell>
        <DoctypesRelationsManager
          attribute={attribute}
          doctypesOptions={doctypesOptions}
        />
      </GrowingCell>

      <ActionsCell>
        {!!actionOptions.length && <DotsMenuLayer options={actionOptions} />}
      </ActionsCell>

      {deleteAttributeModal}

      {isEditionModalOpened && (
        <SettingsAttributeEditModal attribute={attribute} onHide={toggleEditionModal} />
      )}
    </RowContainer>
  );
};
