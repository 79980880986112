import { MeFragment, ProductBaseFragment, ProductUpdateIntegrationsDocument } from '@cycle-app/graphql-codegen';
import { Avatar, Button } from '@cycle-app/ui';
import {
  ChromeIcon, ZapierIcon, WebhookIcon, CsvIcon, PlaceHolderIcon, MakeIcon,
} from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { useOptimizedBooleanState, useSafeMutation } from 'src/hooks';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { Logo } from '../OnboardingLayout/Logo';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Footer } from '../OnboardingLayout/OnboardingLayout.styles';
import {
  AppMain,
  AppSourceList,
  AppSourceListItem,
  AppTitle,
  ButtonOther,
  Main,
  SourceLabel,
  SourceList,
  SourceListContainer,
  SourceName,
  StyledAsideApp,
  StyledCheckboxInput,
  StyledSelectPanel,
  StyledTooltip,
} from './OnboardingSources.styles';
import { sources } from './sources';

interface Props {
  me: MeFragment;
  product: Pick<ProductBaseFragment, 'name' | 'logo' | 'id'>;
}

const initialList = sources.slice(0, 10);
const othersList = sources.slice(10).sort((a, b) => a.label.localeCompare(b.label));

export const OnboardingSources: FC<React.PropsWithChildren<Props>> = ({
  me, product,
}) => {
  const [selected, setSelected] = useState<Array<string>>([]);
  const [list, setList] = useState(initialList);
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const [updateIntegrations, { loading }] = useSafeMutation(ProductUpdateIntegrationsDocument);
  const [isOthersOpen, {
    setTrueCallback: setOpenOthers, setFalseCallback: setCloseOthers,
  }] = useOptimizedBooleanState(false);

  // Scroll to bottom each time we update the length of the list.
  useEffect(() => {
    listContainerRef.current?.scrollTo(0, listContainerRef.current.scrollHeight);
  }, [list.length]);

  const toggleValue = (value: string) => () => {
    setSelected(values => (
      values.includes(value)
        ? values.filter(v => v !== value)
        : [value, ...values]));
  };

  return (
    <OnboardingLayout
      title="Where do you currently capture feedback?"
      aside={(
        <StyledAsideApp
          color={me.color}
          logo={<Logo src={product.logo?.url || ''} name={product.name} />}
          avatar={(
            <Avatar
              user={me}
              size={18}
              userColor={me.color}
              src={me.avatar?.url}
              pending={false}
            />
          )}
          main={(
            <AppMain>
              <AppTitle>Inbox</AppTitle>
              <AppSourceList>
                <AnimatePresence>
                  {selected.map(id => {
                    const source = sources.find(s => s.id === id);
                    return source && (
                      <AppSourceListItem
                        initial={{
                          opacity: 0,
                          translateX: 10,
                        }}
                        animate={{
                          opacity: 1,
                          translateX: 0,
                        }}
                        exit={{
                          opacity: 0,
                          translateX: 10,
                        }}
                        key={source.id}
                      >
                        {source.icon}
                      </AppSourceListItem>
                    );
                  })}
                </AnimatePresence>
                <AppSourceListItem>
                  <StyledTooltip withPortal width="auto" placement="top" content="Zapier integration is available by default">
                    <ZapierIcon />
                  </StyledTooltip>
                </AppSourceListItem>
                <AppSourceListItem>
                  <StyledTooltip withPortal width="auto" placement="top" content="Make integration is available by default">
                    <MakeIcon />
                  </StyledTooltip>
                </AppSourceListItem>
                <AppSourceListItem>
                  <StyledTooltip withPortal width="auto" placement="top" content="Capture feedback anywhere on the web with Chrome extension">
                    <ChromeIcon />
                  </StyledTooltip>
                </AppSourceListItem>
                <AppSourceListItem>
                  <StyledTooltip withPortal width="auto" placement="top" content="Ingest feedback with CSV imports">
                    <CsvIcon />
                  </StyledTooltip>
                </AppSourceListItem>
                <AppSourceListItem>
                  <StyledTooltip withPortal width="auto" placement="top" content="Integrate any service that supports Webhooks with Cycle">
                    <WebhookIcon />
                  </StyledTooltip>
                </AppSourceListItem>
              </AppSourceList>
            </AppMain>
          )}
        />
      )}
      main={(
        <Main
          onSubmit={async (e) => {
            e.preventDefault();
            const result = await updateIntegrations({
              variables: {
                productId: product.id,
                integrations: selected,
              },
            });
            if (result.data?.updateProduct?.id) {
              setOnboarding({ screen: LightOnboardingScreen.Linear });
            }
          }}
        >
          <SourceListContainer ref={listContainerRef}>
            <SourceList>
              {list.map(source => (
                <li key={source.id}>
                  <StyledCheckboxInput
                    $isSelected={selected.includes(source.id)}
                    checked={selected.includes(source.id)}
                    id={source.id}
                    onChange={toggleValue(source.id)}
                    label={(
                      <SourceLabel>
                        {source.icon}
                        <SourceName>{source.label}</SourceName>
                      </SourceLabel>
                    )}
                  />
                </li>
              ))}
              <li>
                <DropdownLayer
                  visible={isOthersOpen}
                  hide={setCloseOthers}
                  placement="right"
                  popperOptions={{
                    modifiers: [{
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['top'],
                      },
                    }],
                  }}
                  content={
                    (
                      <StyledSelectPanel
                        options={othersList.filter(source => !list.find(({ id }) => id === source.id)).map(source => ({
                          label: source.label,
                          icon: source.icon,
                          value: source.id,
                        }))}
                        onOptionChange={({ value }) => {
                          const source = sources.find(({ id }) => id === value);
                          if (source) {
                            setList(currentList => [...currentList, source]);
                            toggleValue(value)();
                          }
                        }}
                        clearSearchOnCreate
                        onCreateOption={async (value) => {
                          const v = value.trim();
                          if (list.some(s => s.id === v)) return;
                          setList(currentList => [...currentList, {
                            id: v,
                            label: v,
                            icon: <PlaceHolderIcon />,
                          }]);
                          toggleValue(v)();
                        }}
                      />
                    )
                  }
                >
                  <ButtonOther forceFocus={isOthersOpen} full variant="secondary" onClick={setOpenOthers}>Other...</ButtonOther>
                </DropdownLayer>
              </li>
            </SourceList>
          </SourceListContainer>
          <Footer>
            <Button disabled={!selected.length} isLoading={loading} type="submit" size="M">Next</Button>
          </Footer>
        </Main>
      )}
    />
  );
};
