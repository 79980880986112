import {
  WheelIcon, InboxIcon, BulbIcon, RoadmapIcon, CustomerIconOutline, ReleaseIcon,
  UsersIcon, TagIcon, ShapesIcon, BagIcon, CodeBorderIcon,
  AiOutlineIcon,
} from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { PageId, SettingsPageId } from 'src/constants/routing.constant';
import { useRouteAccess, useFeatureFlag } from 'src/hooks';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useUrl } from 'src/hooks/useUrl';
import { setSettingsFromUrl } from 'src/reactives/lastView.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';

import { closeCommandBar } from '../modals/useCommandBarModal';

type SettingsItem = {
  id: string;
  name: string;
  pageId: SettingsPageId;
  icon: ReactNode;
  disabled?: boolean;
};

const iconProps = (height: number) => ({
  style: {
    height,
    width: 20,
  },
});

export const useSettingsResult = (): Array<CommandActionCategory> => {
  const { navigate } = useNavigateToSettings();
  const getUrl = useUrl();
  const hasBillingAccess = useRouteAccess(PageId.SettingsBilling);
  const { isEnabled: isSettingsApiEnabled } = useFeatureFlag('settings-api');

  const settings: SettingsItem[] = [
    {
      id: 'general',
      name: 'General',
      pageId: PageId.Settings,
      icon: <WheelIcon {...iconProps(18)} />,
    },
    {
      id: 'ai',
      name: 'Cycle AI',
      pageId: PageId.SettingsAi,
      icon: <AiOutlineIcon {...iconProps(19)} />,
    },
    {
      id: 'members',
      name: 'Members',
      pageId: PageId.SettingsUsers,
      icon: <UsersIcon {...iconProps(19)} />,
    },
    {
      id: 'feedback',
      name: 'Feedback',
      pageId: PageId.SettingsFeedback,
      icon: <InboxIcon {...iconProps(17)} />,
    },
    {
      id: 'insights',
      name: 'Insights',
      pageId: PageId.SettingsInsights,
      icon: <BulbIcon {...iconProps(19)} />,
    },
    {
      id: 'roadmaps',
      name: 'Roadmaps',
      pageId: PageId.SettingsRoadmaps as SettingsPageId,
      icon: <RoadmapIcon {...iconProps(17)} />,
    },
    {
      id: 'releases',
      name: 'Releases',
      pageId: PageId.SettingsReleases as SettingsPageId,
      icon: <ReleaseIcon {...iconProps(17)} />,
    },
    {
      id: 'customers',
      name: 'Customers',
      pageId: PageId.SettingsCustomers,
      icon: <CustomerIconOutline {...iconProps(18)} />,
    },
    {
      id: 'properties',
      name: 'Properties',
      pageId: PageId.SettingsAttributes,
      icon: <TagIcon {...iconProps(18)} />,
    },
    {
      id: 'integrations',
      name: 'Integrations',
      pageId: PageId.SettingsIntegrations,
      icon: <ShapesIcon {...iconProps(19)} />,
    },
    {
      id: 'api',
      name: 'API',
      pageId: PageId.SettingsAPI,
      icon: <CodeBorderIcon {...iconProps(14)} />,
      disabled: !isSettingsApiEnabled,
    },
    {
      id: 'billing',
      name: 'Billing',
      pageId: PageId.SettingsBilling,
      icon: <BagIcon {...iconProps(18)} />,
      disabled: !hasBillingAccess,
    },
  ];

  return [{
    id: 'settings',
    label: 'Settings',
    actions: settings
      .filter(setting => !setting.disabled)
      .map(setting => ({
        id: setting.id,
        label: setting.name,
        icon: setting.icon,
        linkTo: getUrl(setting.pageId),
        onClick: (e) => {
          setSettingsFromUrl();
          if (!e.metaKey) closeCommandBar();
        },
        onSelect: () => {
          setSettingsFromUrl();
          closeCommandBar();
          navigate(setting.pageId);
        },
      })),
  }];
};
