import { Color, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Flex, Tag, InfiniteScroll, SelectOption } from '@cycle-app/ui';
import { ReleaseIcon, CalendarEditIcon, TrashIcon, LinkArrowIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { useTheme } from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { FeedbackLifecycle } from 'src/components/FeedbackLifecycle';
import { NewBadge } from 'src/components/NewBadge';
import { ReleaseEditModal, ReleaseRemoveModal } from 'src/components/ReleaseModals';
import { ReleasesEmpty } from 'src/components/ReleasesEmpty/';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { HELP_URL_RELEASES } from 'src/constants/help.constants';
import { PageId } from 'src/constants/routing.constant';
import { ReleasesProvider, useReleasesContext } from 'src/contexts/releasesContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useProductBase, navigate } from 'src/hooks';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useChangelogUpdate } from 'src/hooks/releases/useChangelogUpdate';
import { openRemoveRelease, openUpdateRelease } from 'src/reactives/releases.reactive';
import { changelogUrl } from 'src/utils/changelog.utils';

import { Page } from '../SettingsWorkflows/SettingsWorkflows.styles';
import imageDark from './public-changelog-dark.png';
import imageLight from './public-changelog-light.png';
import { SettingsChangelog } from './SettingsChangelog';
import {
  Title, Description, Date,
  ReleasesListContainer,
  ReleaseItems, ReleaseItem,
  Header,
  PublicChangeLogContainer,
  PublicChangelogImage,
  PublicChangelogTitle,
  PublicChangelogDescription,
  ButtonEnable,
} from './SettingsReleases.styles';

export const SettingsReleases = () => {
  const product = useProductBase();
  const { changelog } = useChangelog();
  const { isDark } = useTheme();
  const {
    changelogUpdate, isLoading: isUpddateChangelogLoading,
  } = useChangelogUpdate();
  const isChangelogPublished = changelog?.isPublished;
  if (!product) return null;
  return (
    <Page>
      <Header>
        <SettingsViewHeader
          title="Releases"
          description="Communicate your releases"
          helpUrl={HELP_URL_RELEASES}
        />

        <FeedbackLifecycle step="release" />
      </Header>

      {isChangelogPublished && (
        <SettingsChangelog />
      )}
      {!isChangelogPublished && (
        <PublicChangeLogContainer>
          <PublicChangelogImage src={isDark ? imageDark : imageLight} />
          <PublicChangelogTitle>
            <Title>Public changelog</Title>
            <div>
              <NewBadge size="M" />
            </div>
          </PublicChangelogTitle>
          <PublicChangelogDescription>
            Create a public changelog and update it with the latest features.
            <br />
            Fetch the release notes, preview, and publish. It&apos;s that easy.
          </PublicChangelogDescription>
          <ButtonEnable
            variant="secondary"
            size="M"
            isLoading={isUpddateChangelogLoading}
            onClick={async () => {
              if (changelog) {
                await changelogUpdate({
                  id: changelog.id,
                  isPublished: true,
                });
              }
            }}
          >
            Create changelog
          </ButtonEnable>
        </PublicChangeLogContainer>
      )}

      <ReleasesProvider productId={product.id}>
        <ReleasesList />
      </ReleasesProvider>
    </Page>
  );
};

const ReleasesList = () => {
  const releasesCount = useReleasesContext(ctx => ctx.releasesList.length);
  const releasesList = useReleasesContext(ctx => ctx.releasesList);
  const hasNextPage = useReleasesContext(ctx => ctx.hasNextPage);
  const loadMore = useReleasesContext(ctx => ctx.loadMore);
  const isLoadingMore = useReleasesContext(ctx => ctx.isLoadingMore);
  const hasReleases = !!releasesCount;
  return (
    <>
      <ReleasesListContainer>
        {hasReleases && (
          <Flex $justify="space-between">
            <Title>Releases</Title>
          </Flex>
        )}
        <InfiniteScroll
          hasMoreData={hasNextPage}
          loadMore={loadMore}
          isLoading={isLoadingMore}
        >
          <ReleaseItems $hasMinDimensions={!hasReleases}>
            {hasReleases ? releasesList.filter(release => !!release.date).map(release => (
              <ReleaseItem key={release.id}>
                <Flex $gap={8} $align="flex-start">
                  <Date>
                    {toShortLocaleDateString(release.date)}
                  </Date>
                  {!!release.title && (
                    <>
                      <Description>·</Description>
                      <Description>{release.title}</Description>
                    </>
                  )}
                </Flex>
                <Flex $gap={8}>
                  {release.publicStatus === ReleasePublicStatus.Published && (
                    <Tag color={Color.C}>
                      Published
                    </Tag>
                  )}
                  <ReleaseItemAction
                    releaseId={release.id}
                    isReleasePublished={release.publicStatus === ReleasePublicStatus.Published}
                    publicId={release.publicRelease?.id || null}
                  />
                </Flex>
              </ReleaseItem>
            )) : <ReleasesEmpty fromSettings />}
          </ReleaseItems>
        </InfiniteScroll>
      </ReleasesListContainer>

      <ReleaseEditModal />
    </>
  );
};

const ReleaseItemAction = ({
  isReleasePublished, releaseId, publicId,
}: { isReleasePublished: boolean; releaseId: string; publicId: string | null }) => {
  const { changelog } = useChangelog();
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);
  const url = `${changelogUrl(productSlug, changelog?.domain)}/${publicId ? `release-${publicId}` : ''}`;
  const options: SelectOption[] = [
    {
      value: 'go',
      label: 'Go to release',
      icon: <ReleaseIcon />,
      onSelect: () => navigate(PageId.Release, { releaseId }),
    },
    {
      value: 'open',
      label: 'Open in Changelog',
      icon: <LinkArrowIcon size={16} />,
      ...(!isReleasePublished || !changelog?.isPublished) && {
        disabled: true,
        tooltipContent: 'Publish the changelog and the release first',
      },
      onSelect: () => {
        window.open(url, '_blank');
      },
    },
    {
      value: 'edit',
      label: 'Edit release info',
      icon: <CalendarEditIcon width={14} style={{ marginLeft: '2px' }} />,
      onSelect: openUpdateRelease(releaseId),
      ...isReleasePublished && {
        disabled: true,
        tooltipContent: 'Make updates or unpublish first',
      },
    },
    {
      value: 'delete',
      label: 'Delete',
      icon: <TrashIcon />,
      onSelect: openRemoveRelease(releaseId),
      ...isReleasePublished && {
        disabled: true,
        tooltipContent: 'Unpublish first',
      },
    },
  ];
  return (
    <>
      <DotsMenuLayer
        placement="right-start"
        options={options}
      />
      <ReleaseRemoveModal releaseId={releaseId} />
    </>
  );
};
