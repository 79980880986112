import { Skeleton, CompanyLogo } from '@cycle-app/ui';
import { CloseIcon, SummaryIcon, ExpandIcon } from '@cycle-app/ui/icons';

import { CustomerLinkedProfiles } from 'src/components/CustomerProfile/CustomerLinkedProfiles';
import { CustomerActionsMenu } from 'src/components/CustomersList/CustomerActionsMenu';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ExportQuotesFromCustomer } from 'src/components/ExportQuotesModal';
import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';

import { DocsCountCustomer } from './DocsCountCustomer';
import {
  ProfileContainer, ProfileHeader, ProfileActions, ExpandButton, CloseButton,
  ImageContainer, StyledCustomerAvatar, ImageSkeleton, Skeletons,
  ProfileInfos, Summary, SummaryTitle, InfosTitle, InfosSubtitle, TextButton, Left,
} from './Profile.styles';
import { CustomerSummaryModal } from './SummaryModal';
import { TabsCustomer } from './TabsCustomer';

type Props = {
  customerId: string;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
};

export const CustomerProfile = ({
  customerId, onClose, onExpand,
}: Props) => {
  const {
    customer, loading,
  } = useCustomer(customerId);
  const company = customer?.company;
  return (
    <ErrorBoundary>
      <BoardConfigContextProvider>
        <ProfileContainer>
          <ProfileHeader>
            <ProfileActions>
              {onClose && (
                <CloseButton onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
              )}
              {onExpand && (
                <ExpandButton
                  tooltip="Open full page"
                  onClick={onExpand}
                >
                  <ExpandIcon size={18} />
                </ExpandButton>
              )}
            </ProfileActions>

            {customer && (
              <CustomerActionsMenu customer={customer} />
            )}
          </ProfileHeader>

          <ImageContainer>
            {loading ? <ImageSkeleton /> : (customer && (
              <StyledCustomerAvatar
                customer={customer}
                companyTitle={company?.name}
                companyTooltip={company ? 'Open company' : undefined}
                onCompanyClick={() => {
                  if (company) openCompanyModal(company.id);
                }}
              />
            ))}
          </ImageContainer>

          <ProfileInfos>
            {loading ? (
              <Skeletons>
                <Skeleton height={16} width={150} />
                <Skeleton height={16} width={250} />
              </Skeletons>
            ) : (customer && (
              <>
                <InfosTitle>{customer.name}</InfosTitle>
                <InfosSubtitle>
                  <Left>
                    {customer.email && (
                      <TooltipClickToCopy
                        title={customer.email}
                        value={customer.email}
                      >
                        {props => (
                          <TextButton {...props}>
                            {customer.email}
                          </TextButton>
                        )}
                      </TooltipClickToCopy>
                    )}

                    {company
                      ? (
                        <TextButton
                          tooltip="Open company"
                          tooltipPlacement="top"
                          onClick={() => openCompanyModal(company.id)}
                        >
                          <CompanyLogo company={company} size="S" />
                          {company.name}
                        </TextButton>
                      )
                      : (
                        <div>No company</div>
                      )}

                    {!!customer.profiles?.length && (
                      <CustomerLinkedProfiles customer={customer} />
                    )}
                  </Left>
                </InfosSubtitle>
              </>
            ))}
          </ProfileInfos>

          <Summary>
            <SummaryTitle>
              <SummaryIcon />
              Summary
            </SummaryTitle>
            <DocsCountCustomer customerId={customerId} />
          </Summary>

          <TabsCustomer customerId={customerId} />

          <ExportQuotesFromCustomer customerId={customerId} />
          <CustomerSummaryModal customerId={customerId} />
        </ProfileContainer>
      </BoardConfigContextProvider>
    </ErrorBoundary>
  );
};
