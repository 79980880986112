import { useApolloClient } from '@apollo/client';
import { Editor as TiptapEditor } from '@tiptap/core';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { Editor, ReadOnlyEditor } from 'src/components/Editor';
import { EDITOR_MARGIN } from 'src/constants/editor.constants';
import { RELEASE_NOTE_EDITOR } from 'src/constants/releases.constants';
import { useMe } from 'src/hooks';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
import { setReleaseNoteEditor } from 'src/reactives/releaseNote.reactive';
import { ActionId } from 'src/services/editor/editorActions';

import { ReleaseNoteSkeleton } from './ReleaseNote.styles';

export const ReleaseNoteEditor = ({
  releaseNoteId, isReadOnly, isEnabled, onLoad,
}: {
  releaseNoteId: string;
  isReadOnly?: boolean;
  isEnabled?: boolean;
  onLoad?: (editor: TiptapEditor) => void;
}) => {
  const theme = useTheme();
  const { me } = useMe();
  const cursorName = `${me.firstName} ${me.lastName}`;

  const prevNoteId = useRef<string | null>();
  const [isEditorInit, setIsEditorInit] = useState(false);

  const {
    provider,
    yDoc,
    isSync,
  } = useRealtimeEditorConfig({
    docId: releaseNoteId,
    isEnabled,
    isEditorInit,
  });

  useEffect(() => {
    if (isSync && !isEditorInit) {
      prevNoteId.current = releaseNoteId;
      setIsEditorInit(true);
    } else if (isEditorInit && releaseNoteId !== prevNoteId.current) {
      prevNoteId.current = releaseNoteId;
      setIsEditorInit(false);
    }
  }, [isEditorInit, isSync, releaseNoteId]);
  const { cache } = useApolloClient();

  if (!isEditorInit) return <ReleaseNoteSkeleton />;

  if (isReadOnly) {
    return (
      <ReadOnlyEditor
        className={RELEASE_NOTE_EDITOR}
        style={{ padding: `24px ${EDITOR_MARGIN}px` }}
        interactive
        collaboration={yDoc ? { document: yDoc } : undefined}
        onEditorReady={editor => {
          setReleaseNoteEditor({ editor });
          onLoad?.(editor);
        }}
      />
    );
  }

  return (
    <Editor
      className={RELEASE_NOTE_EDITOR}
      // TODO: replace the placeholder when AI is ready
      // emptyPlaceholder="Type '/' for commands, start writing your release note, or get help from Cycle AI below"
      emptyPlaceholder="Type '/' for commands, start writing your release note"
      hideQuickActions
      disabledActions={[
        ActionId.MentionDoc,
        ActionId.MentionUser,
        ActionId.GithubIssue,
        ActionId.Linear,
        ActionId.Notion,
        ActionId.TurnTextIntoDocMention,
        ActionId.TurnTextIntoDocMentionLastUsed,
        ActionId.TurnTextIntoGithubIssueMention,
        ActionId.TurnTextIntoInsight,
        ActionId.TurnTextIntoLinearMention,
        ActionId.Comments,
        ActionId.Audio,
        ActionId.Confluence,
        ActionId.Embed,
        ActionId.File,
        ActionId.Jira,
        ActionId.LinearProject,
        ActionId.Table,
        ActionId.TableOfContent,
        ActionId.Transcript,
      ]}
      showAddTemplate={false}
      smallEmptyBlock
      isReadOnly={isReadOnly}
      collaboration={yDoc ? { document: yDoc } : undefined}
      cursors={provider ? {
        provider,
        user: {
          color: theme.userColors.main,
          name: cursorName,
        },
      } : undefined}
      onEditorReady={editor => {
        setReleaseNoteEditor({ editor });
      }}
      disabledFeatures={[
        'image-resizable',
        'cover',
        'transcript',
      ]}
      onUpdate={({ html }) => {
        // There is no real time for hasContent, but we may need the latest value,
        // in instance to display the content icon in the release note cards.
        cache.modify({
          id: releaseNoteId,
          fields: {
            hasContent: () => !!html && html !== '<p class="editor-paragraph"></p>',
          },
        });
      }}
    />
  );
};
