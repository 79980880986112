import { useDocV2 } from 'src/hooks';

import { QuoteCard } from '../CustomerCompanyProfile/QuoteCard';
import { Doc } from './Ask.types';

export const AnswerDoc = ({ doc }: { doc: Doc }) => {
  // The ask subscription already includes the fragment in `DocNode` query,
  // so there should not be loading/request here.
  const data = useDocV2(doc.id);
  return (data.doc && <QuoteCard doc={data.doc} />) || null;
};
