import { QueryHookOptions, useQuery } from '@apollo/client';
import { CustomerDocument, CustomerFragment } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

type Options = Omit<QueryHookOptions<{ node: CustomerFragment }>, 'variables'>;

export const useCustomer = (customerId: string | undefined, options?: Options) => {
  const {
    data, loading,
  } = useQuery(CustomerDocument, {
    fetchPolicy: 'cache-first',
    skip: !customerId,
    ...options,
    variables: {
      id: customerId,
    },
  });
  const customer = useMemo(() => (data?.node?.__typename === 'Customer' ? data.node : null), [data]);
  return {
    customer,
    loading,
  };
};
