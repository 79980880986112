import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { DocOptionsProps, DocOptionsStateless } from 'src/components/DocOptions/DocOptions';
import { ACCEPTED_COVER_IMAGE_FORMAT } from 'src/constants/upload.constant';
import { useDocContext } from 'src/contexts/docContext';
import { useAddDocToaster } from 'src/hooks/doc/useAddDocToaster';
import { useDocItemHoverIdSelector } from 'src/reactives/docItem.reactive';
import { useIsDocSelected } from 'src/reactives/selection.reactive';
import { Layer } from 'src/types/layers.types';
import { ViewType } from 'src/types/viewType.types';

export type DocItemOptionsProps = Omit<DocOptionsProps, 'doc'> & {
  updateDocCover?: (file: File) => void;
};

export const DocItemOptions = ({
  isNewInbox, ...props
}: DocItemOptionsProps) => {
  const docId = useDocContext(ctx => ctx.id);
  const isHovered = useDocItemHoverIdSelector(id => id === docId || isNewInbox);
  const [isVisible, setVisible] = useState(false);
  if (!isHovered && !isVisible) return <EmptySpace />;
  return (
    <DocItemOptionsVisible
      {...props}
      isNewInbox={isNewInbox}
      isVisible={isVisible}
      setVisible={setVisible}
    />
  );
};

const EmptySpace = () => (
  <div
    style={{
      width: 24,
      height: 24,
    }}
  />
);

export const DocItemOptionsVisible = ({
  viewType, updateDocCover, isNewInbox, ...props
}: DocItemOptionsProps & {
  isVisible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const doc = useDocContext();
  const docCoverUrl = doc.cover?.url;

  const isSelected = useIsDocSelected(doc.id);
  const addDocToaster = useAddDocToaster();

  const onSelectProperty = useCallback((isNotCompatible: boolean) => {
    if (isNotCompatible) {
      addDocToaster({
        docId: doc.id,
        title: 'Property updated',
        message: tag => (
          <span>
            {tag ?? 'Your doc'}
            {' '}
            was successfully updated and left the view
          </span>
        ),
      });
    }
  }, [addDocToaster, doc.id]);

  const onAddCoverClicked = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = Object.keys(ACCEPTED_COVER_IMAGE_FORMAT).join(',');
    input.onchange = e => {
      e.stopPropagation();
      const file = input.files?.[0];
      if (!file) return;
      updateDocCover?.(file);
      input.remove();
    };
    input.click();
  }, [updateDocCover]);

  return (
    <DocOptionsStateless
      doc={doc}
      dropdownPlacement={viewType === ViewType.List ? 'bottom-end' : 'bottom-start'}
      onAddCoverClicked={onAddCoverClicked}
      viewType={viewType}
      secondaryBg={viewType === ViewType.List || !docCoverUrl}
      disabled={!!isSelected}
      onSelectProperty={onSelectProperty}
      layer={Layer.DocItemDropdown}
      context="doc-item"
      isNewInbox={isNewInbox}
      {...props}
    />
  );
};
