import { useQuery } from '@apollo/client';
import {
  AddNewDocAttributeValue,
  AddNewDocDocument,
  DocChildFragment,
  DoctypeFragment,
  SearchDocHierarchyDocument,
  SearchDocHierarchyQuery,
  SearchDocHierarchyQueryVariables,
} from '@cycle-app/graphql-codegen';
import { ActionButton, Emoji, TextHighlighter, Spinner } from '@cycle-app/ui';
import { CloseIcon, AddIcon } from '@cycle-app/ui/icons';
import { useListNav, nodeToArray } from '@cycle-app/utilities';
import {
  useState,
  useRef,
  HTMLAttributes,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { useUpdateChildCache } from 'src/hooks/api/cache/cacheHierarchy';
import { useSearchSuggestionDocChildren } from 'src/hooks/api/queries/useSearchSuggestionDocChildren';
import { useProductBase } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { addDocAutomationWatch } from 'src/reactives/docAutomationWatch.reactive';
import { useGetDocTypeEmoji } from 'src/reactives/docTypes.reactive';
import { useSuggestionStatusIds } from 'src/reactives/productStatus.reactive';
import { Layer } from 'src/types/layers.types';
import { refetchBoardWithConfigQuery } from 'src/utils/boardConfig/boardConfig.util';
import { getDocKey } from 'src/utils/doc.util';
import { doctypeHasAutomation } from 'src/utils/doctype.automation.util';

import { DocLinearAutoCreate } from '../DocLinearAutoCreate';
import {
  Container,
  Input,
  Clear,
  DropdownLayerStyled,
  DropdownResult,
  Line,
  TagStyled,
  CreateSpan,
  MarkNewDoc,
  AddButton,
  SectionTitle,
  NotFound,
  TitleSpinner,
  DocTitle,
  LabelContainer,
  Content,
  ResultLabel,
} from './InputLinkDoc.styles';

const SUGGESTION_PREFIX = 'suggestion';

interface Props extends HTMLAttributes<HTMLInputElement> {
  onDocLinked?: (docId: string, doctypeId: string, created: boolean, doc?: DocChildFragment) => Promise<void>;
  doctype: DoctypeFragment;
  parentId?: string;
  hideInput?: VoidFunction;
  autoFocus?: boolean;
  inheritedAttributes?: AddNewDocAttributeValue[];
  sourceId?: string;
  customerId?: string;
  docFilter?: (node: DocChildFragment) => boolean;
  label?: string;
  placeholder?: string;
}

const CREATE_DOC_ID = 'create-doc';

const InputLinkDoc = ({
  onDocLinked,
  hideInput,
  doctype,
  autoFocus,
  parentId,
  inheritedAttributes,
  sourceId,
  customerId,
  docFilter,
  label,
  ...inputProps
}: Props) => {
  const product = useProductBase();
  const [createDoc, { loading }] = useSafeMutation(AddNewDocDocument);
  const [term, setTerm] = useState('');
  const [toSearch, setToSearch] = useState('');
  const [isFocus, setIsFocus] = useState(autoFocus);

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const updateChild = useUpdateChildCache();
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  const [withLinearChecked, { setValueCallback: setWithLinearChecked }] = useOptimizedBooleanState(doctypeHasAutomation(doctype.id));
  const create = useCallback(async () => {
    if (!product?.id || !term || loading) return;

    setToSearch('');
    const withLinear = withLinearChecked && doctypeHasAutomation(doctype.id);
    const newDocData = await createDoc({
      variables: {
        title: term,
        doctypeId: doctype.id,
        productId: product.id,
        parentId,
        attributes: inheritedAttributes,
        ...sourceId && {
          source: {
            sourceId,
          },
        },
        customer: customerId ? { id: customerId } : undefined,
        withLinear,
      },
      refetchQueries: refetchBoardWithConfigQuery,
    });

    setTerm('');

    const newDoc = newDocData?.data?.addNewDoc;
    if (!newDoc) return;
    if (withLinear) {
      addDocAutomationWatch(newDoc.id);
    }

    if (parentId) {
      await updateChild({
        newParentId: parentId,
        docs: [{
          docId: newDoc.id,
          parentId: undefined,
        }],
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onDocLinked?.(newDoc.id, doctype.id, true, newDoc);
  }, [
    createDoc,
    term,
    onDocLinked,
    doctype,
    product,
    parentId,
    updateChild,
    inheritedAttributes,
    loading,
    customerId,
    sourceId,
    withLinearChecked,
  ]);

  const statusIds = useSuggestionStatusIds();

  const docsQuery = useQuery<SearchDocHierarchyQuery, SearchDocHierarchyQueryVariables>(SearchDocHierarchyDocument, {
    variables: {
      text: toSearch,
      productId: product?.id ?? '',
      statusIds,
      doctypeIds: [doctype.id],
      ...parentId && { hasParent: false },
    },
    skip: !product?.id,
    fetchPolicy: 'cache-and-network',
  });

  const onSearchDocDebounced = useDebouncedCallback((text: string) => setToSearch(text), INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => {
    onSearchDocDebounced(term);
  }, [onSearchDocDebounced, term]);

  const docsResult = useMemo(
    () => nodeToArray(docsQuery.data?.searchDoc).filter(node => !docFilter || docFilter(node.doc)),
    [docFilter, docsQuery.data],
  );

  const suggestionQuery = useSearchSuggestionDocChildren({
    docId: parentId as string,
    doctypeIds: [doctype.id],
  }, {
    skip: !parentId || toSearch !== '',
  });

  const suggestioResult = useMemo(
    () => {
      return docFilter ? suggestionQuery.docs.filter(docFilter) : suggestionQuery.docs;
    },
    [docFilter, suggestionQuery.docs],
  );

  const items = useMemo(() => [
    ...term !== '' ? [{
      id: CREATE_DOC_ID,
      onSelect: create,
    }] : [],
    ...suggestioResult.map((doc) => ({
      id: `${SUGGESTION_PREFIX}${doc.id}`,
      onSelect: () => {
        setTerm('');
        setToSearch('');
        return onDocLinked?.(doc.id, doctype.id, false, doc);
      },
    })),
    ...docsResult.map(node => ({
      id: node.doc.id,
      onSelect: () => {
        setTerm('');
        setToSearch('');
        return onDocLinked?.(node.doc.id, doctype.id, false, node.doc);
      },
    })),
  ], [term, create, suggestioResult, docsResult, onDocLinked, doctype.id]);

  const isDropdownVisible = isFocus;
  const optionsValues = useMemo(() => items.map(item => item.id), [items]);

  const [isInput, toggleInput] = useReducer(b => !b, !!hideInput);

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    enabled: isDropdownVisible,
    value: null,
    optionsValues,
    autoFocus: true,
    onSelect: async (itemId) => {
      await items.find(i => itemId && i.id.includes(itemId))?.onSelect?.();
      toggleInput();
      hideInput?.();
    },
  });

  const onHideDropdown = useCallback(() => setIsFocus(false), []);

  const emoji = useGetDocTypeEmoji(doctype.id);

  return isInput || hideInput
    ? (
      <DropdownLayerStyled
        placement="bottom-start"
        content={renderDropdown()}
        visible={isDropdownVisible}
        hide={onHideDropdown}
        layer={Layer.DropdownModalZ3}
        animation={false}
      >
        <Container>
          <Input
            ref={inputRef}
            onChange={(e) => setTerm(e.target.value)}
            value={term}
            autoFocus
            onFocus={() => setIsFocus(true)}
            onBlur={(e) => {
              if (e.target.id !== listRef.current?.id) {
                setIsFocus(false);
              }
            }}
            onKeyDown={e => {
              if (e.code === 'Escape') {
                e.stopPropagation();
                toggleInput();
                hideInput?.();
              }
            }}
            {...inputProps}
          />
          <Clear>
            <ActionButton onClick={hideInput ?? toggleInput}>
              <CloseIcon size={10} />
            </ActionButton>
          </Clear>
        </Container>
      </DropdownLayerStyled>
    )
    : (
      <AddButton
        iconStart={<AddIcon />}
        onClick={toggleInput}
      >
        {label}
      </AddButton>
    );
  function renderDropdown() {
    return (
      <DropdownResult
        {...listProps}
        ref={listRef}
      >
        {term !== '' && (
          <Line
            selected={selected === CREATE_DOC_ID}
            {...itemProps(CREATE_DOC_ID)}
          >
            <CreateSpan>Create </CreateSpan>
            <MarkNewDoc>{term}</MarkNewDoc>
            {loading && <Spinner />}
            {doctypeHasAutomation(doctype.id) && isLinearInstalled && (
              <DocLinearAutoCreate checked={withLinearChecked} onChange={setWithLinearChecked} doctypeId={doctype.id} />
            )}
          </Line>
        )}
        {term === '' && (
          <>
            <SectionTitle>
              Recommended
              {suggestionQuery.isLoading && <TitleSpinner />}
            </SectionTitle>

            {!suggestionQuery.isLoading && suggestionQuery.docs.length === 0 && (
              <NotFound>No recommendation found</NotFound>
            )}

            {suggestionQuery.docs.map((doc) => (
              <Line
                key={doc.id}
                selected={selected === `${SUGGESTION_PREFIX}${doc.id}`}
                {...itemProps(doc.id)}
              >
                <Emoji emoji={emoji} />
                <TagStyled>
                  {getDocKey(product?.key, doc.publicId)}
                </TagStyled>
                <DocTitle>{doc.title}</DocTitle>
              </Line>
            ))}

            <SectionTitle>
              Recent
              {docsQuery.loading && docsResult.length === 0 && <TitleSpinner />}
            </SectionTitle>
          </>
        )}
        {docsResult.map(node => (
          <Line
            key={node.doc.id}
            selected={selected === node.doc.id}
            {...itemProps(node.doc.id)}
          >
            <LabelContainer>
              <ResultLabel>
                <Emoji emoji={emoji} />
                <TagStyled>
                  {getDocKey(product?.key, node.doc.publicId)}
                </TagStyled>
                <TextHighlighter
                  searchWords={[term]}
                  textToHighlight={node.doc.title}
                  className="highlight"
                />
              </ResultLabel>

              {node.highlightContent && !node.highlightTitle && (
                <Content
                  className="highlight"
                  dangerouslySetInnerHTML={{
                    __html: `<p>${node.highlightContent}</p>`,
                  }}
                />
              )}
            </LabelContainer>
          </Line>
        ))}
      </DropdownResult>
    );
  }
};

export default InputLinkDoc;
