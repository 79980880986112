/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
import {
  typo, boxShadowZ2, truncate, ActionButton, Button, Input, ShyScrollbarCss,
} from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled, { keyframes } from 'styled-components';

import { DropdownLayer } from 'src/components/DropdownLayer';

const scaleDown = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
`;

export const StyledDropdown = styled(DropdownLayer)`
  border-radius: 16px;
  height: 450px;
  ${p => p.theme.isDark && `
    border: 1px solid ${ColorTheme.Grey850};
  `}

  &::before {
    content: '';
    position: absolute;
    width: 151.19%;
    height: 138.44%;
    border-radius: 258.89px;
    ${p => (p.theme.isDark
    ? `
      background: linear-gradient(0deg, rgba(34, 34, 34, 0.76) 0%, rgba(34, 34, 34, 0.76) 100%), linear-gradient(150deg, #AE8FFF 20.37%, #00D2F3 61.53%, #4DED8D 98.93%);
    ` : `
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%), linear-gradient(137deg, #AE8FFF 12.8%, #00D2F3 57.75%, #4DED8D 98.59%);
    `)}
    filter: blur(92px);
    z-index: -1;
    left: -40%;
    bottom: -20%;
    transform-origin: bottom left;
    animation: 5s ${scaleDown} forwards running;
  }
`;

export const Container = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  --bg: ${p => (p.theme.isDark ? p.theme.colors.background.secondary : p.theme.colors.background.primary)};
  background: var(--bg);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.body500}
  > span {
    ${truncate}
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Action = styled(ActionButton)`
  width: 24px;
  height: 24px;
`;

export const Content = styled.div<{
  $withBorder?: boolean;
  $withGradient?: boolean;
}>`
  flex: 1;
  overflow: hidden;
  display: flex;
  margin: 0 -16px;
  position: relative;
  ${p => p.$withBorder && `
    border-bottom: 1px solid ${p.theme.colors.border.primary};
  `}

  ${p => p.$withGradient && `
    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      background: linear-gradient(180deg, var(--bg) 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 1;
    }
  `}
`;

interface ScrollableProps {
  // In instance, use default column for example items as they are stacked on the top first.
  $isColumn?: boolean;
}

export const Scrollable = styled.div<ScrollableProps>`
  ${ShyScrollbarCss}
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
  padding: 6px 16px 6px 16px;
  padding-bottom: ${p => (p.theme.isDark ? 0 : 6)}px;
  width: 100%;

  ${p => p.$isColumn && 'flex-direction: column;'}
`;

export const Footer = styled.div`
  padding-top: 16px;
`;

export const QuestionInput = styled(Input)`
  height: 36px;
  margin: 0 -2px 2px 0;
  input {
    border-radius: 8px;
  }
`;

const Item = styled.div`
  overflow-wrap: anywhere;
  user-select: text;
  border-radius: 8px;
  max-width: 400px;
  flex: none;
  position: relative;
`;

export const Question = styled(Item)`
  align-self: flex-end;
  background: ${ColorTheme.Blue500};
  color: ${ColorTheme.White};
  padding: 4px 8px;
`;

export const QuestionDate = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.disabled};
`;

export const Answer = styled(Item)`
  padding: 6px 16px 6px 8px;
  align-self: flex-start;
  ${boxShadowZ2}
  ${p => (p.theme.isDark ? `
    background: ${ColorTheme.Grey850};
  ` : `
    border: 1px solid ${p.theme.colors.border.primary};
  `)}
`;

const loadingAnimation = keyframes`
  0% { opacity: 0.1 }
  25% { opacity: 0.2 }
  50% { opacity: 0.3 }
  75% { opacity: 0.2 }
  100% { opacity: 0.1 }
`;

export const LoadingAnswer = styled(Answer)`
  ${typo.body500}
  color: ${p => p.theme.colors.text.disabled};
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  height: 32px;
  > div {
    width: 4px;
    height: 4px;
    opacity: 0.3;
    border-radius: 50%;
    background: ${p => p.theme.colors.text.primary};
    animation: ${loadingAnimation} 1.5s infinite linear;
  }
  > div:nth-child(2) {
    animation-delay: 0.5s;
  }
  > div:nth-child(3) {
    animation-delay: 1s;
  }
`;

export const CopyButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: -10px;
`;

export const CopyButton = styled(ActionButton)`
  border: 1px solid ${p => p.theme.colors.border.primary};
  width: 22px;
  height: 22px;
  opacity: 0;
  background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : p.theme.colors.background.primary)};
  ${Answer}:hover & {
    opacity: 1;
  }
`;

export const TextButton = styled(Button).attrs({ variant: 'nospace' })`
  margin-top: 8px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 4px;
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  :hover {
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const AnswerDocsContainer = styled.div`
  position: absolute;
  inset: 0;
  background: var(--bg);
  margin: 0 16px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AnswerDocsScrollable = styled(Scrollable)`
  padding-top: 0;
  flex-direction: column;
`;

export const DocCard = styled(Answer)`
  width: 370px;
  max-width: 100%;
  padding: 8px 12px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LineClamp = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const DocFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px 16px;
  flex-wrap: wrap;
`;

export const DocCustomer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${typo.caption400}
`;

export const DocCompany = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${p => (p.theme.isDark ? ColorTheme.Grey700 : p.theme.colors.text.secondary)};
`;

export const DocDate = styled.span`
  ${typo.caption400}
  color: ${p => (p.theme.isDark ? ColorTheme.Grey700 : p.theme.colors.text.secondary)};
  ${truncate}
`;

export const AnswerTextContainer = styled.div<{ $withCursor: boolean }>`
  ${p => p.$withCursor && `
    p:last-of-type:after {
      content: '';
      display: inline-block;
      width: 2px;
      height: 16px;
      position: relative;
      top: 4px;
      left: 3px;
      border-radius: 3px;
      background: linear-gradient(176.66deg, #AE8FFF -6.92%, #00D2F3 48.35%, #4DED8D 98.56%);
    }
  `}
`;

export const AskExampleList = styled.ul`
  li + li {
    margin-top: 12px;
  }
`;

export const AskExample = styled(Button)`
  ${boxShadowZ2}
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  padding-left: 8px;
  padding-right: 8px;

  > span {
    text-align: left;
  }
`;

export const AnswerParametersContainer = styled.div`
  margin-bottom: 12px;
`;

export const AnswerParametersTag = styled.div`
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.baseColors.Grey200)};
  color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey400 : p.theme.baseColors.Grey800)};
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
`;

export const AnswerParametersCompanyButton = styled(Button)`
  --bg: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.baseColors.Grey200)};
  --color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey400 : p.theme.baseColors.Grey800)};
  padding: 0 4px;
  border-radius: 4px;
  height: 20px;
  display: inline-flex;
  border: none;
  vertical-align: middle;
  margin-top: -2px;
`;

export const TipMessage = styled.div`
  ${typo.caption}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
  margin-bottom: 16px;

  svg {
    vertical-align: middle;
    margin-right: 8px;
  }
`;
