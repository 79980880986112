import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AllStatusesIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M7.58388 1.94586C7.58388 1.56971 7.88881 1.26477 8.26497 1.26477C11.9848 1.26477 15.0002 4.28014 15.0002 8C15.0002 11.7199 11.9848 14.7352 8.26497 14.7352C7.88881 14.7352 7.58388 14.4303 7.58388 14.0541C7.58388 13.678 7.88881 13.3731 8.26497 13.3731C11.2325 13.3731 13.638 10.9676 13.638 8C13.638 5.03246 11.2325 2.62695 8.26497 2.62695C7.88881 2.62695 7.58388 2.32202 7.58388 1.94586ZM5.53985 3.33906C5.97425 3.08835 6.12312 2.53296 5.87226 2.09854C5.62155 1.66414 5.06616 1.51543 4.63175 1.76613C4.19734 2.01701 4.04863 2.5724 4.29933 3.00665C4.55021 3.44105 5.1056 3.58992 5.53985 3.33906ZM3.26955 4.03555C3.70396 4.28641 3.85284 4.84182 3.60197 5.27623C3.35127 5.71048 2.79586 5.85935 2.36145 5.60865C1.92703 5.35779 1.77833 4.80238 2.02903 4.36797C2.27989 3.93355 2.8353 3.78484 3.26955 4.03555ZM2.8163 7.99936C2.8163 8.50091 2.40962 8.90743 1.90807 8.90743C1.40652 8.90743 1 8.50091 1 7.99936C1 7.49781 1.40652 7.09129 1.90807 7.09129C2.40962 7.09129 2.8163 7.49781 2.8163 7.99936ZM5.53993 12.66C5.97435 12.9109 6.12305 13.4663 5.87235 13.9007C5.62149 14.3349 5.06608 14.4838 4.63167 14.2331C4.19742 13.9822 4.04855 13.4268 4.29941 12.9924C4.55011 12.5582 5.10552 12.4093 5.53993 12.66ZM3.27176 11.9636C3.70617 11.7129 3.85503 11.1575 3.60418 10.7231C3.35346 10.2887 2.79806 10.1398 2.36366 10.3907C1.92925 10.6416 1.78054 11.197 2.03124 11.6312C2.28212 12.0656 2.83751 12.2145 3.27176 11.9636ZM10.9285 7.08878C11.1984 6.85213 11.2254 6.44146 10.9888 6.17152C10.7521 5.90158 10.3415 5.87459 10.0715 6.11124L7.5 8.36559L6.42849 7.42624C6.15855 7.18959 5.74788 7.21658 5.51123 7.48652C5.27458 7.75646 5.30157 8.16713 5.57151 8.40378L7.07151 9.71878C7.31673 9.93375 7.68327 9.93375 7.92849 9.71878L10.9285 7.08878Z"
      fill="currentcolor"
    />
  </Svg>
);
