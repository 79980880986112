import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Tooltip, Flex } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { memo, useCallback, useState } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocAttributes } from 'src/components/DocAttributes';
import DocChildren from 'src/components/DocChildren/DocChildren';
import { DocChildrenList } from 'src/components/DocChildren/DocChildrenList';
import { DocItemComments } from 'src/components/DocComments/DocComments';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocInsights, DocQuotes } from 'src/components/DocInsights';
import { DocParentDropdown } from 'src/components/DocParentDropdown';
import DocPrimaryAttributes from 'src/components/DocPrimaryAttributes/DocPrimaryAttributes';
import { DocProcessButton } from 'src/components/DocProcessButton';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useDocContext } from 'src/contexts/docContext';
import { useAddDocToaster } from 'src/hooks/doc/useAddDocToaster';
import { useParentPage } from 'src/hooks/usePageId';
import { useDocItemHoverIdSelector } from 'src/reactives/docItem.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useDocsSelectedLength, useIsDocSelected } from 'src/reactives/selection.reactive';
import { ViewType } from 'src/types/viewType.types';
import { getCommentsCount } from 'src/utils/comments.utils';
import { canHaveInsightChildren, canHaveChildren, isCycleWithoutFileNorUrlSource } from 'src/utils/doc.util';
import { isBuiltIn, isFeedback } from 'src/utils/docType.util';

import {
  CardContainer, BulkContainer, TitleContainer, TitleContent, Title, Description, Viewers, PlaceholderMulti, ParentContainer, StyledViewCard,
} from './DocItem.styles';
import { DocItemProps } from './DocItem.types';
import { DocItemCheckbox } from './DocItemCheckbox';
import { DocItemContextTitle } from './DocItemContextTitle';
import { DocItemOptions } from './DocItemOptions';
import { DocItemViewer } from './DocItemViewer';

const LIMIT_DISPLAY_VIEWERS_LENGTH = 3;

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

export type DocItemCardProps = Omit<DocItemProps, 'isGroupInBoardView' | 'isLazy' | 'docIndex'> & {
  isFocused?: boolean;
  isReadOnly?: boolean;
  updateDocCover?: (file: File) => void;
};

export const DocItemCard = ({
  isDragging = false,
  asPlaceholder = false,
  direction,
  viewType,
  showAssignee,
  showCover,
  showDocParent,
  showDocId,
  showDocType,
  showSource,
  showChildren,
  showInsights,
  showCustomer,
  showComments,
  showProperties = true,
  showStatus,
  showRelease,
  showLinear,
  showAiState,
  description,
  isSelectable = false,
  isFocused = false,
  isReadOnly = true,
  updateDocCover,
  isNewInbox,
}: DocItemCardProps) => {
  const docId = useDocContext(ctx => ctx.id);
  const isInsight = useDocContext(ctx => ctx.isInsight);
  const docTypeId = useDocContext(ctx => ctx.doctype.id);
  const docType = useGetDocType(docTypeId);
  const canHaveParent = docType?.parents?.edges.length;
  const docTitle = useDocContext(ctx => ctx.title);
  const docCoverUrl = useDocContext(ctx => ctx.cover?.url);
  const isCreating = useDocContext(ctx => ctx._creating);
  const isSelected = useIsDocSelected(docId);
  const selectedLength = useDocsSelectedLength();
  const parentPage = useParentPage();
  const isMobile = useIsMobile();

  const [isDocOptionsVisible, setDocOptionsVisibility] = useState(false);

  const defaultTitle = description != null ? (
    <TitleContainer>
      <TitleContent>
        {docTitle && <Title>{docTitle}</Title>}
        <Description>{description}</Description>
      </TitleContent>
    </TitleContainer>
  ) : (docTitle || null);

  return (
    <CardContainer
      id={docId}
      className="doc-item"
      $disableActions={selectedLength > 0}
      $viewType={viewType}
      $isDocOptionsVisible={isDocOptionsVisible}
    >
      {isDragging && selectedLength > 1 && !isReadOnly && (
        <PlaceholderMulti direction={direction} />
      )}

      <DocItemViewers />

      <StyledViewCard
        $isNewInbox={isNewInbox}
        isCreating={!!isCreating}
        isDragging={isDragging}
        direction={direction}
        isHover={isFocused}
        isSelected={isSelectable && isSelected}
        asPlaceholder={asPlaceholder}
        viewType={viewType}
        coverUrl={showCover ? docCoverUrl : undefined}
        preTitle={showDocParent && !!canHaveParent && <DocItemParent viewType={viewType} />}
        leftTitle={viewType === ViewType.List &&
            (showChildren || showDocId || showDocType || showStatus || showRelease || (showCustomer && isNewInbox && !isInsight)) && (
              <DocItemPreTitle
                showChildren={showChildren}
                showDocId={showDocId}
                showDocType={showDocType}
                showStatus={showStatus}
                showRelease={showRelease}
                showCustomer={showCustomer && isNewInbox && !isInsight}
                viewType={viewType}
              />
        )}
        title={isInsight
          ? <DocItemContextTitle showCustomer={showCustomer} viewType={viewType} />
          : defaultTitle}
        showActions={!isReadOnly && !isMobile}
        selectedLength={selectedLength}
        actions={!isReadOnly && !isMobile && (
          <Flex $gap={4}>
            {viewType === ViewType.Kanban && <ProcessButton docId={docId} />}
            <DocItemOptions
              updateDocCover={updateDocCover}
              viewType={viewType}
              onVisibilityChange={setDocOptionsVisibility}
              isNewInbox={isNewInbox}
            />
          </Flex>
        )}
        preToolbar={!isReadOnly && (
          <DocItemAttributes
            viewType={viewType}
            isDragging={isDragging}
            showDocId={showDocId}
            showDocType={showDocType}
            showSource={showSource}
            showProperties={showProperties}
            showStatus={showStatus}
            showRelease={showRelease}
            showLinear={showLinear}
            showAiState={showAiState}
          />
        )}
        sectionStart={viewType === ViewType.Kanban && (
          <DocItemSectionStart
            showChildren={showChildren}
            showInsights={showInsights}
            showComments={showComments}
          />
        )}
        sectionEnd={(
          ((showChildren && viewType === ViewType.Kanban) ||
            showComments ||
            showAssignee ||
            showCustomer ||
            showInsights ||
            parentPage === 'inbox')) &&
          !isReadOnly &&
          (
            <DocItemSectionEnd
              viewType={viewType}
              showComments={showComments}
              showAssignee={showAssignee}
              showCustomer={showCustomer}
              showInsights={showInsights}
              isNewInbox={isNewInbox}
            />
          )}
      >
        {isSelectable && !isDragging && !asPlaceholder && !isReadOnly && <DocItemBulkCheckbox viewType={viewType} />}
      </StyledViewCard>
    </CardContainer>
  );
};

type DocItemPreTitleProps = Pick<
DocItemCardProps,
'showChildren' | 'showDocType' | 'showDocId' | 'showStatus' | 'viewType' | 'showRelease' | 'showCustomer'
>;

const DocItemPreTitle = memo(({
  showDocType, showDocId, showStatus, viewType, showRelease, showCustomer, ...props
}: DocItemPreTitleProps) => {
  const doc = useDocContext();
  const docType = useGetDocType(doc.doctype.id);
  const isMobile = useIsMobile();
  const docDocSource = useDocContext(ctx => ctx.docSource);

  if (isMobile) return null;

  const showChildren = props.showChildren && canHaveChildren(docType);
  const isCustomerEditable = !docDocSource && !isMobile;
  const isCustomerRemovable = docType?.type === DoctypeType.Custom;

  return (
    <>
      {showChildren && viewType === ViewType.Kanban && (
        <DocChildren doc={doc} context="doc-item" />
      )}

      {showChildren && viewType === ViewType.List && (
        <DocChildrenList />
      )}

      {showCustomer && isFeedback(docType) && (
        <DocCompanyCustomer
          isCompact
          isDisabled={!isCustomerEditable}
          doc={doc}
          canRemoveCustomer={isCustomerRemovable}
        />
      )}

      <DocPrimaryAttributes
        doc={doc}
        showDocId={showDocId}
        showDocType={showDocType}
        isDocTypeReadOnly={isBuiltIn(docType)}
        showStatus={showStatus}
        showRelease={showRelease}
        showLinear={false}
        showAiState={false}
      />
    </>
  );
});

const DocItemViewers = memo(() => {
  const viewers = useDocContext(ctx => ctx._viewers);

  if (!viewers?.length) return null;

  return (
    <Viewers>
      {viewers?.slice(0, LIMIT_DISPLAY_VIEWERS_LENGTH).map(viewerId => (
        <DocItemViewer key={viewerId} userId={viewerId} />
      ))}
    </Viewers>
  );
});

const DocItemParent = memo(({ viewType }: { viewType: ViewType }) => {
  const doc = useDocContext();

  return (
    <ParentContainer viewType={viewType}>
      <DocParentDropdown
        showParentTitle
        docId={doc.id}
        docTypeId={doc.doctype.id}
        minimal
        placeholder="Add"
        absoluteEdit={viewType === ViewType.List}
        context="doc-item"
        viewType={viewType}
        showLinearAutoCreate
      />
    </ParentContainer>
  );
});

type ProcessButtonProps = {
  docId: string;
};

const ProcessButton = memo(({ docId }: ProcessButtonProps) => {
  const docStatusId = useDocContext(ctx => ctx.status?.id);
  const docStatusCategory = useDocContext(ctx => ctx.status?.category);
  const quotesCount = useDocContext(ctx => ctx.quotesCount ?? 0);
  const parentPage = useParentPage();

  if (parentPage !== 'inbox' || !docStatusId || !docStatusCategory) return null;

  return (
    <DocProcessButton
      docId={docId}
      docStatusCategory={docStatusCategory}
      parent="doc-item"
      quotesCount={quotesCount}
    />
  );
});

// eslint-disable-next-line max-len
type DocItemAttributesProps = Pick<DocItemCardProps, 'viewType' | 'isDragging' | 'showDocId' | 'showDocType' | 'showSource' | 'showProperties' | 'showStatus' | 'showRelease' | 'showLinear' | 'showAiState'>;

const DocItemAttributes = memo(({
  viewType, isDragging, showDocId, showDocType, showSource = false, showProperties, showStatus, showRelease, showLinear, showAiState,
}: DocItemAttributesProps) => {
  const doc = useDocContext();
  const displayedPropertiesIds = useBoardConfig(ctx => ctx.displayedPropertiesIds);
  const docSource = useDocContext(ctx => ctx.source);
  const shouldShowSource: boolean = !isCycleWithoutFileNorUrlSource(docSource) && showSource;
  return (
    <DocAttributes
      doc={doc}
      displayedPropertiesIds={showProperties ? displayedPropertiesIds : []}
      viewType={viewType}
      isDragging={isDragging}
      showDocId={showDocId}
      showDocType={showDocType}
      showSource={shouldShowSource}
      showStatus={showStatus}
      showRelease={showRelease}
      showLinear={showLinear}
      showAiState={showAiState}
      displayPrimaryAttributes={viewType === ViewType.Kanban}
      context="doc-item"
    />
  );
});

type DocItemSectionStartProps = Pick<DocItemCardProps, 'showChildren' | 'showInsights' | 'showComments'>;

const DocItemSectionStart = memo(({
  showChildren, showInsights, showComments,
}: DocItemSectionStartProps) => {
  const doc = useDocContext();
  const docType = useGetDocType(doc.doctype.id);
  const isMobile = useIsMobile();

  if (isMobile) return null;

  return (
    <>
      {showComments && (
        <DocItemComments
          docId={doc.id}
          nbComments={getCommentsCount(doc)}
          context="doc-item"
        />
      )}
      {showChildren && canHaveChildren(docType) && (
        <DocChildren
          doc={doc}
          context="doc-item"
        />
      )}
      {showInsights && canHaveInsightChildren(docType) && !quotesEnabled && (
        <DocInsights
          doc={doc}
          context="doc-item"
        />
      )}

      {showInsights && canHaveInsightChildren(docType) && quotesEnabled && (
        <DocQuotes
          doc={doc}
          context="doc-item"
        />
      )}
    </>
  );
});

type DocItemSectionEndProps = Pick<DocItemCardProps, 'isNewInbox' | 'showAssignee' | 'showCustomer' | 'showComments' | 'viewType' | 'showInsights'>;

const DocItemSectionEnd = memo(({
  showAssignee,
  showComments,
  showCustomer,
  showInsights,
  viewType,
  isNewInbox,
}: DocItemSectionEndProps) => {
  const doc = useDocContext();
  const docType = useGetDocType(doc.doctype.id);
  const addDocToaster = useAddDocToaster();
  const docId = useDocContext(ctx => ctx.id);
  const docDocSource = useDocContext(ctx => ctx.docSource);
  const isInsight = useDocContext(ctx => ctx.isInsight);
  const isMobile = useIsMobile();

  const onSelectProperty = useCallback((propertyName: string, isNotCompatible: boolean) => {
    if (isNotCompatible) {
      addDocToaster({
        docId,
        title: `${propertyName} updated`,
        message: tag => (
          <span>
            {tag ?? 'Your doc'}
            {' '}
            was successfully updated and left the view
          </span>
        ),
      });
    }
  }, [docId, addDocToaster]);

  const isAssigneeRemovable = docType?.type === DoctypeType.Custom;
  const isCustomerRemovable = docType?.type === DoctypeType.Custom;
  const isAssigneeEditable = !docDocSource && !isMobile;
  const isCustomerEditable = !docDocSource && !isMobile;

  return (
    <>
      {showComments && viewType === ViewType.List && (
        <DocItemComments
          docId={docId}
          nbComments={getCommentsCount(doc)}
          context="doc-item"
        />
      )}
      {showInsights && viewType === ViewType.List && canHaveInsightChildren(docType) && !quotesEnabled && (
        <DocInsights
          doc={doc}
          context="doc-item"
        />
      )}
      {showInsights && viewType === ViewType.List && canHaveInsightChildren(docType) && quotesEnabled && (
        <DocQuotes
          doc={doc}
          context="doc-item"
        />
      )}
      {showAssignee && docType?.name && (
        <DocAssignee
          docId={docId}
          assignee={doc.assignee}
          onSelect={user => onSelectProperty('Assignee', !user?._compatibleWithBoardConfig)}
          isDisabled={!isAssigneeEditable}
          isRemovable={isAssigneeRemovable}
          context="doc-item"
          docTypeName={docType.name}
          docTypeType={docType.type}
        />
      )}
      {!isInsight && showCustomer && !isNewInbox && (
        <DocCompanyCustomer
          isCompact
          isDisabled={!isCustomerEditable}
          doc={doc}
          canRemoveCustomer={isCustomerRemovable}
        />
      )}
      {viewType === ViewType.List && <ProcessButton docId={docId} />}
    </>
  );
});

const DocItemBulkCheckbox = ({ viewType }: Pick<DocItemCardProps, 'viewType'>) => {
  const docId = useDocContext(ctx => ctx.id);
  const isSelected = useIsDocSelected(docId);
  const isHovered = useDocItemHoverIdSelector(id => id === docId);

  if (!isSelected && !isHovered) return null;

  return (
    <BulkContainer
      $viewType={viewType}
    >
      <Tooltip
        content="Select"
        placement="top"
        withPortal
      >
        <DocItemCheckbox
          docId={docId}
          viewType={viewType}
        >
          <CheckIcon />
        </DocItemCheckbox>
      </Tooltip>
    </BulkContainer>
  );
};
