import { WIDTH_COLLAPSED_SIDEBAR } from '@cycle-app/ui';
import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import { DocStateLoader } from 'src/components/DocStateLoader';
import { DocStateLoaderQuotes } from 'src/components/DocStateLoaderQuotes';
import { ExportQuotesFromDoc } from 'src/components/ExportQuotesModal';
import Portal from 'src/components/Portal/Portal';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { DocPanelProvider } from 'src/contexts/docPanelContext';
import { ExtractQuotesProvider } from 'src/contexts/extractQuotesContext';
import { useDocState } from 'src/hooks/api/useDocState';
import { useDocSubscription } from 'src/hooks/api/useDocSubscription';
import { useFindInsightStateSubscription } from 'src/hooks/api/useFindInsightStateSubscription';
import { useDocsPreview } from 'src/hooks/doc/useDocsPreview';
import { refetchAiGenetatedInsights } from 'src/hooks/insight/useDocInsights.cache';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { useDocId, useDocSlug } from 'src/hooks/usePathParams';
import { PageId, useUrl } from 'src/hooks/useUrl';
import { useIsMobile } from 'src/reactives';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { resetHighlight } from 'src/reactives/highlight.reactive';
import { useGetSidebarCollapsed, useGetSidebarWidth } from 'src/reactives/sidebar.reactive';
import { PortalId } from 'src/types/portal.types';
import { isFeedback } from 'src/utils/docType.util';

import { CreateDocModal } from '../CreateDocModal';
import DocPanelBoardPage from './DocPanelBoardPage/DocPanelBoardPage';
import DocPanelFullPage from './DocPanelFullPage/DocPanelFullPage';

interface Props {
  expanded?: boolean;
}

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

const DocPanel: FC<React.PropsWithChildren<Props>> = ({ expanded = false }) => {
  const isMobile = useIsMobile();
  const getUrl = useUrl();
  const docSlug = useDocSlug();
  if (!expanded && isMobile) return <Redirect to={getUrl(PageId.DocFullPage, { docSlug })} />;
  return <DocPanelPage expanded={expanded} />;
};

const DocPanelPage: FC<React.PropsWithChildren<Props>> = ({ expanded = false }) => {
  const isMobile = useIsMobile();
  const { width } = useGetSidebarWidth();
  const { collapsed } = useGetSidebarCollapsed();
  const {
    doc,
    isLoading: isDocLoading,
    updateDocCover,
    setDocViewCoverInput,
    ...commonProps
  } = useDocPanelProps();

  const docId = doc?.id ?? '';
  const widthSidebar = collapsed ? WIDTH_COLLAPSED_SIDEBAR : width;

  useDocSubscription(docId);

  // Refetch AI generated insights once Find insights has been completed
  useFindInsightStateSubscription(docId, state => {
    if (!quotesEnabled && !state && docId) refetchAiGenetatedInsights(docId);
  });

  const {
    autopilotState,
    findInsightState,
    closeDocState,
  } = useDocState(docId);

  const autopilotLoader = (
    <DocStateLoader
      autopilotState={autopilotState}
      findInsightState={findInsightState}
      close={closeDocState}
    />
  );

  useDocsPreview();
  useEffect(() => {
    setCommandbar({ docId: doc?.id ?? null });
    return () => setCommandbar({ docId: null });
  }, [doc]);

  useEffect(() => {
    return () => {
      // We can keep highlight states between docs, reset only when a leave the route.
      resetHighlight();
    };
  }, []);

  const docPanelBaseProps = {
    doc,
    isDocLoading,
    widthSidebar: isMobile ? 0 : widthSidebar,
    ...commonProps,
  };
  const coverInputEl = (
    <CoverImageInputFile
      ref={(r) => r && setDocViewCoverInput(r)}
      onCoverChanged={updateDocCover}
    />
  );

  const docStateLoaderQuotes = quotesEnabled && !isMobile && doc && isFeedback(doc.doctype) && <DocStateLoaderQuotes />;

  const paramDocId = useDocId();
  if (!paramDocId) return null;

  const content = expanded ? (
    <>
      <DocPanelFullPage
        {...docPanelBaseProps}
        disabled={!quotesEnabled && (!!findInsightState || !!autopilotState)}
      >
        {!quotesEnabled && autopilotLoader}
        {docStateLoaderQuotes}
      </DocPanelFullPage>
      {coverInputEl}
    </>
  ) : (
    <Portal portalId={PortalId.DocPanel}>
      <DocPanelBoardPage
        {...docPanelBaseProps}
        disabled={!quotesEnabled && (!!findInsightState || !!autopilotState)}
      >
        {!quotesEnabled && autopilotLoader}
        {docStateLoaderQuotes}
      </DocPanelBoardPage>
      {coverInputEl}
    </Portal>
  );

  return (
    <>
      <DocPanelProvider docId={paramDocId} doc={doc}>
        <ExtractQuotesProvider docId={docId}>
          {content}
        </ExtractQuotesProvider>
      </DocPanelProvider>
      <ExportQuotesFromDoc docId={doc?.id} />
    </>
  );
};

export default DocPanel;

export const DocPanelWithBoardConfig = () => {
  return (
    <BoardConfigContextProvider>
      <DocPanel />
    </BoardConfigContextProvider>
  );
};

export const DocPanelFull = () => {
  return (
    <BoardConfigContextProvider>
      <DocPanel expanded />
      <CreateDocModal />
    </BoardConfigContextProvider>
  );
};
