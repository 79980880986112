import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { CellText, Tooltip } from '@cycle-app/ui';
import { ReactNode, FC, ComponentProps } from 'react';

import { StyledCellContent, StyledCustomerAvatar } from './CustomerTableNameCell.styles';

type Props = ComponentProps<typeof StyledCellContent> & {
  customer: CustomerFragment;
  name?: ReactNode | null;
  isCompact?: boolean;
};

export const CustomerTableNameCell: FC<React.PropsWithChildren<Props>> = ({
  customer, name, isCompact = false, ...props
}) => (
  <Tooltip
    content="Open customer"
    placement="top"
    withPortal
    withWrapper={false}
  >
    <StyledCellContent $isInteractive={!!props.onClick} $limitSize {...props}>
      <StyledCustomerAvatar size="S" customer={customer} hideCompany />
      <CellText $isCompact={isCompact}>
        {name}
      </CellText>
    </StyledCellContent>
  </Tooltip>
);
