import { AttributeTextValueFragment, CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import {
  SelectOption,
  CheckboxInput,
  TextType,
  CustomPropertyInputText,
} from '@cycle-app/ui';
import { capitalize } from '@cycle-app/utilities';
import { ChangeEvent, FC } from 'react';
import {
  useController, Control, Path, FieldValues, UnpackNestedValue, PathValue,
} from 'react-hook-form';

import { AttributeOptionsManager } from 'src/components/AttributeOptionsManager';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { getLastCustomFieldUsed, setLastCustomFieldUsed } from 'src/reactives';
import { Layer } from 'src/types/layers.types';
import { CustomPropertyFormData } from 'src/types/property.types';

import {
  Caret,
  Container,
  InputContainer,
  InputContent,
  Label,
  ToggleContainer,
} from './CustomPropertyFormField.styles';

const inputType: Record<
| 'AttributeDateDefinition'
| 'AttributeEmailDefinition'
| 'AttributeNumberDefinition'
| 'AttributePhoneDefinition'
| 'AttributeTextDefinition'
| 'AttributeUrlDefinition',
TextType
> = {
  AttributeDateDefinition: 'date',
  AttributeEmailDefinition: 'email',
  AttributeNumberDefinition: 'number',
  AttributePhoneDefinition: 'phone',
  AttributeTextDefinition: 'text',
  AttributeUrlDefinition: 'text',
};

type CustomPropertyFormFieldProps<T> = {
  control: Control<T>;
  data: CustomPropertyFormData;
  autoFocus?: boolean;
  layer?: Layer;
  disabled?: boolean;
};

export function CustomPropertyFormField<T extends FieldValues>({
  control,
  data,
  autoFocus,
  layer,
  disabled,
}: CustomPropertyFormFieldProps<T>) {
  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    // TODO: Try to fix typing issue here (https://codesandbox.io/s/react-hook-form-use-controller-generic-problem-dcp3s?file=/src/App.tsx:739-750)
    name: data.id as Path<T>,
    control,
    defaultValue: getLastCustomFieldUsed(data.id) as UnpackNestedValue<PathValue<T, Path<T>>>,
  });
  /*
   * // TODO: to remove when support multi-select
   * We don't support it since we can not set multiple value on doc creation mutation yet
   */
  if (data.definition.__typename === 'AttributeMultiSelectDefinition') return null;

  if (data.definition.__typename === 'AttributeSingleSelectDefinition') {
    const possibleValues = data.definition.values.edges.map(e => e.node);
    return (
      <Container>
        <Label>{capitalize(data.definition.name)}</Label>
        <CustomFieldSingleSelect
          id={data.definition.id}
          values={possibleValues}
          onChange={onChange}
          currentValue={possibleValues.find(v => v.id === value) || null}
          layer={layer}
          attributeDefinition={data.definition}
          disabled={disabled}
        />
      </Container>
    );
  }

  if (data.definition.__typename === 'AttributeCheckboxDefinition') {
    return (
      <Container>
        <Label>{capitalize(data.definition.name)}</Label>
        <ToggleContainer>
          <CheckboxInput
            id={data.definition.id}
            checked={!!value || false}
            onChange={() => {
              onChange(!value);
            }}
            disabled={disabled}
          />
        </ToggleContainer>
      </Container>
    );
  }

  if (
    data.definition.__typename === 'AttributeDateDefinition' ||
    data.definition.__typename === 'AttributeEmailDefinition' ||
    data.definition.__typename === 'AttributeNumberDefinition' ||
    data.definition.__typename === 'AttributePhoneDefinition' ||
    data.definition.__typename === 'AttributeTextDefinition' ||
    data.definition.__typename === 'AttributeUrlDefinition'
  ) {
    return (
      <Container>
        <Label>{capitalize(data.definition.name)}</Label>
        <CustomFieldText
          type={data.definition.__typename}
          onChange={onChange}
          name={data.definition.name}
          value={(value || '') as string}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      </Container>
    );
  }

  return null;
}

type CustomFieldSingleSelectProps = {
  id: CustomPropertyFormData['definition']['id'];
  onChange: (...event: any[]) => void;
  values: Omit<AttributeTextValueFragment, '__typename'>[];
  currentValue: Omit<AttributeTextValueFragment, '__typename'> | null;
  autoFocus?: boolean;
  layer?: Layer;
  attributeDefinition: CustomAttributeDefinitionFragment;
  disabled?: boolean;
};

const CustomFieldSingleSelect: FC<React.PropsWithChildren<CustomFieldSingleSelectProps>> = ({
  id,
  onChange,
  values,
  currentValue,
  layer,
  attributeDefinition,
  disabled,
}) => {
  const [isVisible, {
    setFalseCallback,
    setTrueCallback,
  }] = useOptimizedBooleanState(false);

  const options: SelectOption[] = values.map(v => ({
    label: v.value,
    value: v.id,
    selected: currentValue?.id === v.id,
  }));
  const selectedOption = options.find(o => o.selected);
  return (
    <DropdownLayer
      layer={layer || Layer.DropdownModalZ3}
      visible={isVisible}
      hide={setFalseCallback}
      placement="bottom-start"
      content={(
        <AttributeOptionsManager
          attributeDefinition={attributeDefinition}
          options={options}
          showWarningOnNoneValue={false}
          shouldCreateValueInComponent
          isMulti={false}
          onSelect={option => {
            onChange(option.value);
            setFalseCallback();
            setLastCustomFieldUsed(id, option.value);
          }}
          onCreate={() => { }}
        />
      )}
    >
      <InputContainer onClick={setTrueCallback} $disabled={disabled}>
        <InputContent>
          {selectedOption?.label ?? 'Choose from list'}
        </InputContent>
        <Caret direction={isVisible ? 'top' : 'bottom'} />
      </InputContainer>
    </DropdownLayer>
  );
};

type CustomFieldProps = {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type: CustomPropertyFormData['definition']['__typename'];
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;
};

const CustomFieldText: FC<React.PropsWithChildren<CustomFieldProps>> = ({
  name,
  onChange,
  type,
  value,
  autoFocus,
  disabled,
}) => {
  if (
    // Not needed if but fixing types
    type === 'AttributeDateDefinition' ||
    type === 'AttributeEmailDefinition' ||
    type === 'AttributeNumberDefinition' ||
    type === 'AttributePhoneDefinition' ||
    type === 'AttributeTextDefinition' ||
    type === 'AttributeUrlDefinition'
  ) {
    return (
      <CustomPropertyInputText
        type={inputType[type]}
        variant="dropdown"
        hasHelper={false}
        onInputChange={onChange}
        values={[value || '']}
        placeholder={`Enter a ${name}`}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    );
  }

  return null;
};
