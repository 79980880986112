import { HocuspocusProvider } from '@hocuspocus/provider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Doc } from 'yjs';

import { getDoc, setDoc } from 'src/reactives';
import { RouteParams } from 'src/types/routes.types';

export interface RealtimeEditorConfig {
  yDoc: Doc | null;
  provider: HocuspocusProvider | null;
  isSync: boolean;
}

interface Params {
  docId: string | undefined | null;
  isEnabled?: boolean;
  isEditorInit?: boolean;
  disableEditorOnDisconnect?: boolean;
}

export default function useRealtimeEditorConfig({
  docId,
  isEnabled = true,
  isEditorInit = false,
  disableEditorOnDisconnect = false,
}: Params): RealtimeEditorConfig {
  /**
   * The eslint disable is needed to let us ass the `docId` value which is
   * necessary when the doc change so we need to re-open sockets
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const collaborativeDoc = useMemo(() => (isEnabled ? new Doc() : null), [isEnabled, docId]);
  const [isSyncState, setIsSyncState] = useState(false);
  const previousClientID = useRef<number | null>(null);
  const routeParams = useParams<RouteParams>();

  const yprovider = useRef<HocuspocusProvider | null>(null);

  // Initialize the Hocuspocus provider on mount, and destroy it on unmount
  useEffect(() => {
    if (
      !import.meta.env.VITE_WS_API_SYNC_SERVER ||
      !docId ||
      !isEnabled ||
      !collaborativeDoc
    ) {
      return () => {};
    }

    yprovider.current = new HocuspocusProvider({
      url: `${import.meta.env.VITE_WS_API_SYNC_SERVER}/sync/doc/${docId}`,
      name: docId,
      document: collaborativeDoc,
      token: JSON.parse(localStorage.getItem('auth') || '{}').token,
      preserveConnection: false,
      forceSyncInterval: 5000,
      // eslint-disable-next-line no-console
      onConnect: () => console.debug('[Hocuspocus] connected'),
      // eslint-disable-next-line no-console
      onDestroy: () => console.debug('[Hocuspocus] destroyed'),
    });

    return () => {
      yprovider.current?.destroy();
    };
  }, [docId, isEnabled, collaborativeDoc, disableEditorOnDisconnect]);

  useEffect(() => {
    setDoc({ yDoc: collaborativeDoc });
  }, [collaborativeDoc]);

  const isSync = previousClientID.current === collaborativeDoc?.clientID
    ? isSyncState
    : false;

  useEffect(() => {
    if (isEditorInit) {
      const {
        docId: id, isEditorSyncing,
      } = getDoc();

      if (!id || isEditorSyncing === !isSync) return;

      setDoc({ isEditorSyncing: !isSync });
    }
  }, [isSync, isEditorInit]);

  useEffect(() => {
    if (yprovider && collaborativeDoc) {
      previousClientID.current = collaborativeDoc.clientID;

      if (yprovider.current) {
        setIsSyncState(yprovider.current.synced);
        // yprovider.on('status', (event: any) => {
        //   console.info(`Socket ${docId} - ClientId ${collaborativeDoc.clientID} - ${event.status}`);
        // });

        yprovider.current.on('sync', (status: boolean) => {
          setIsSyncState(status);
        });
      }
    }
  }, [yprovider, setIsSyncState, collaborativeDoc]);

  useEffect(() => {
    if (routeParams.docSlug && isEditorInit) {
      const { isEditorSyncing } = getDoc();
      if (isEditorSyncing === !isSync) return;
      setDoc({ isEditorSyncing: !isSync });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSync, isEditorInit]);

  return {
    yDoc: collaborativeDoc,
    provider: yprovider.current,
    isSync,
  };
}
