import { useState } from 'react';

import { CompanyDomainForm } from 'src/components/CompanyDomain/CompanyDomainForm';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { DomainButton } from './Profile.styles';

type Props = {
  companyId: string;
};

export const DomainEmpty = ({ companyId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { company } = useCompany(companyId);

  if (!isEditing) {
    return (
      <DomainButton onClick={() => setIsEditing(true)}>
        Add domain…
      </DomainButton>
    );
  }

  return (
    <CompanyDomainForm
      companyId={companyId}
      name={company?.name ?? ''}
      domain={company?.domain}
      logo={company?.logo}
      hide={() => setIsEditing(false)}
    />
  );
};
