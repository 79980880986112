import { AiState } from '@cycle-app/graphql-codegen';
import { Editor } from '@tiptap/core';
import { FC, RefObject, useRef, useCallback } from 'react';

import DocEditor from 'src/components/DocEditor/DocEditor';
import { DocReleaseNoteSection } from 'src/components/DocReleaseNoteSection';
import { FeedbackPreviewSection } from 'src/components/FeedbackPreviewSection';
import { QuoteCards } from 'src/components/QuoteCards';
import { QuotesSummary } from 'src/components/QuotesSummary';
import { EDITOR_MARGIN, QUOTE_CARD_WIDTH, QUOTE_BUTTON_WIDTH } from 'src/constants/editor.constants';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useDocDocSource, useIsOffline, useIsRoadmapsEnabled, useDocInsights } from 'src/hooks';
import useDocCoverDropzone from 'src/hooks/doc/useCoverDropzone';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { isFeedback, isInsight } from 'src/utils/docType.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import DocPanelCover from '../DocPanelCover/DocPanelCover';
import { DocPanelBody } from './DocPanelBody';
import {
  HeaderContainer,
  ScrollableContent,
  DocPanelActions,
  DocPanelDocAttributes,
  CoverContainer,
  DocPanelEditableTitle,
  EditorWrapper,
  DocParentTitle,
  StyledDocParentDropdown,
  PreTitle,
  QuotesSummarySection,
  MainContent,
  DocPanelSection,
  QuoteCardsContainer,
} from './DocPanelContent.styles';
import { useQuoteCardsCollapsed } from './useQuoteCardsCollapsed';

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

interface Props extends Omit<DocPanelBaseProps, 'getDropzonePropsTopBar'> {
  containerRef: RefObject<HTMLDivElement>;
  doc: FullDocWithPublicId;
  isDraggingCover: boolean;
  isUploadingCover: boolean;
  onEscapeEdition?: VoidFunction;
  focusOnMount?: boolean;
  disabled?: boolean;
}

export const DocPanelContent: FC<React.PropsWithChildren<Props>> = ({
  containerRef,
  doc,
  getDropzonePropsCover,
  mode = 'edit-doc',
  onOpenCoverInputFile,
  onTitleUpdated,
  onEscapeEdition,
  focusOnMount,
  isDraggingCover: isDraggingCoverFromTopOrCover,
  isUploadingCover: isUploadingCoverFromTopOrCover,
  disabled = false,
}) => {
  const {
    blockId,
    docSource,
    isLoading: isDocSourceLoading,
  } = useDocDocSource({
    // Empty string is used to skip the query in case is not a built-in insight
    docId: isInsight(doc.doctype) ? doc.id : '',
  });
  const {
    getRootProps: getDropzonePropsTitleAndAttrs,
    isDragActive: isDragActiveFromTitleAndAttrs,
    isUploadingCover: isUploadingCoverTitleAndAttrs,
  } = useDocCoverDropzone({ docId: doc.id });
  const isOffline = useIsOffline();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const isDraggingCover = isDraggingCoverFromTopOrCover || isDragActiveFromTitleAndAttrs;
  const isUploadingCover = isUploadingCoverFromTopOrCover || isUploadingCoverTitleAndAttrs;
  const showStatus = isFeedback(doc.doctype) ? true : isRoadmapsEnabled;

  const focusToContent = useCallback(() => {
    const editor = containerRef.current?.querySelector('.ProseMirror');
    if (!(editor instanceof HTMLElement)) return;
    editor?.focus();
  }, [containerRef]);

  const isMobile = useIsMobile();

  // The TipTap component
  const docEditor = useRef<Editor>();
  // The ProseMirror element
  const proseMirrorElement = useRef<HTMLDivElement | null>(null);

  const isReleasesEnabled = useIsReleasesEnabled();

  const setEditor = useDocPanelContext(ctx => ctx.setEditor);
  const docPanelEditor = useDocPanelContext(ctx => ctx.editor);

  const quoteCardsEnabled = quotesEnabled && isFeedback(doc.doctype) && !isMobile;
  const query = useDocInsights(doc.id, {
    aiStates: [AiState.UserValidated, null],
    skip: !quoteCardsEnabled,
  });
  const showQuoteCards = quoteCardsEnabled && query.insights.length > 0;

  const {
    quoteCardsCollapsed,
    containerRef: docContentRef,
  } = useQuoteCardsCollapsed({ disabled: !showQuoteCards });

  // Space for the absolutely positioned feature cards
  let paddingRight = 0;
  if (showQuoteCards && quoteCardsCollapsed !== null) {
    paddingRight = EDITOR_MARGIN + (quoteCardsCollapsed ? QUOTE_BUTTON_WIDTH : QUOTE_CARD_WIDTH);
  }

  // Quote from block feature
  const quoteFromBlockEnabled = quotesEnabled && isFeedback(doc.doctype) && !isMobile && !isOffline && !disabled;
  // Extra padding for the "add quote" button. TODO: refactor with an editorMargin variable instead of EDITOR_MARGIN
  const paddingLeft = quoteFromBlockEnabled ? 20 : 0;

  return (
    <ScrollableContent
      $disabled={disabled}
      ref={docContentRef}
    >
      <MainContent
        style={{ paddingLeft }}
        animate={{ paddingRight }}
      >
        {doc.cover?.url && (
          <CoverContainer>
            <DocPanelCover
              docId={doc.id}
              coverUrl={doc.cover.url}
              isDragActive={isDraggingCover}
              getDropzoneProps={getDropzonePropsCover}
              onUpdateCoverClicked={onOpenCoverInputFile}
            />
          </CoverContainer>
        )}

        <HeaderContainer
          {...getDropzonePropsTitleAndAttrs()}
        // Intentional empty function to prevent the dropzone to open the finder on `Enter`
          onKeyDown={undefined}
          coloredTopBorder={!doc?.cover?.url && (isDraggingCover || isUploadingCover)}
        >
          <PreTitle>
            {isMobile && (
              <StyledDocParentDropdown
                docId={doc.id}
                docTypeId={doc.doctype.id}
                size="S"
                showIcon
                showParentTitle={false}
                minimal
                absoluteEdit
                showLinearAutoCreate
              >
                {doc.parent?.title && (
                  <DocParentTitle>
                    {` · ${doc.parent.title}`}
                  </DocParentTitle>
                )}
              </StyledDocParentDropdown>
            )}
            <DocPanelActions
              doc={doc}
              onAddCoverClicked={onOpenCoverInputFile}
              isDraggingCover={isDraggingCover}
              isUploadingCover={isUploadingCover}
              docSource={docSource}
            />
          </PreTitle>
          <DocPanelEditableTitle
            id={doc.id}
            title={doc.title}
            onTitleUpdated={onTitleUpdated}
            onNext={() => containerRef.current?.click()}
            onEscape={onEscapeEdition}
            onEnter={focusToContent}
            focusEndOnMount={focusOnMount}
          />
          <DocPanelDocAttributes
            doc={doc}
            showDoctype={!isFeedback(doc.doctype)}
            showDocId={!isFeedback(doc.doctype)}
            showAssignee
            editor={docPanelEditor}
            showStatus={showStatus}
          />
        </HeaderContainer>

        <DocPanelBody quoteFromBlockEnabled={quoteFromBlockEnabled}>
          {showQuoteCards && quoteCardsCollapsed !== undefined && (
            <DocPanelSection>
              <QuoteCardsContainer>
                <QuoteCards
                  insights={query.insights}
                  collapsed={quoteCardsCollapsed}
                />
              </QuoteCardsContainer>
            </DocPanelSection>
          )}

          {quotesEnabled && isFeedback(doc.doctype) && (
            <QuotesSummarySection>
              <QuotesSummary
                docId={doc.id}
                docContentElement={docContentRef.current}
                initialExpanded={!!doc.quotesCount}
              />
            </QuotesSummarySection>
          )}

          {isReleasesEnabled && doc.doctype.release && doc.releaseNote && (
            <DocReleaseNoteSection
              releaseNote={doc.releaseNote}
              docId={doc.id}
            />
          )}
          {isInsight(doc.doctype) && docSource && (
            <FeedbackPreviewSection
              docId={doc.id}
              blockId={blockId}
              docSource={docSource}
              isLoading={isDocSourceLoading}
            />
          )}

          <EditorWrapper
            ref={el => {
              proseMirrorElement.current = el?.querySelector('.ProseMirror') ?? null;
            }}
            $isDisabled={isOffline}
            onClick={
      undefined
      // () => {
      /**
       * We deactivate this cause is cause some unwanted editor focus when
       * user clicks on some elements like modal to add file/images for
       * instance.
       */
      // Create a new paragraph when clicking on div elements below the editor
      // TODO: we should find a better way to do this
      // if (!(e.target instanceof HTMLElement) || e.target.tagName !== 'DIV') return;
      // ProseMirror handles clicks itself
      // if (proseMirrorElement.current?.contains(e.target)) return;
      // docEditor.current?.chain().focus('end').createParagraphNear().run();
      // }
    }
          >
            <DocEditor
              docId={doc.id}
              parentRef={containerRef}
              displayLoader={mode === 'edit-doc'}
              onEscape={onEscapeEdition}
              showAddTemplate
              showIntegrations
              onEditorReady={editor => {
                docEditor.current = editor;
                setEditor(editor);
              }}
            />
          </EditorWrapper>
        </DocPanelBody>
      </MainContent>
    </ScrollableContent>
  );
};
