import { Skeleton } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon, SummaryIcon, UsersIcon, DollarIcon } from '@cycle-app/ui/icons';

import { CompanyActionsMenu } from 'src/components/CustomersList/CompanyActionsMenu';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ExportQuotesFromCompany } from 'src/components/ExportQuotesModal';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { CustomAttributes } from './CustomAttributes';
import { DocsCountCompany } from './DocsCountCompany';
import { DomainEmpty } from './DomainEmpty';
import { ExternalIds } from './ExternalIds';
import {
  ProfileContainer, ProfileHeader, ProfileActions, CloseButton, ExpandButton, StyledCompanyLogo, ImageSkeleton,
  ProfileInfos, Summary, SummaryTitle, ImageContainer, Skeletons,
  InfosTitle, InfosSubtitle, CompanyLink, Left, Right, BuiltInProperty,
} from './Profile.styles';
import { CompanySummaryModal } from './SummaryModal';
import { TabsCompany } from './TabsCompany';

type Props = {
  companyId: string;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
};

export const CompanyProfile = ({
  companyId, onClose, onExpand,
}: Props) => {
  const {
    company, isLoading,
  } = useCompany(companyId);

  let url = company?.domain;
  if (!company?.domain?.startsWith('https://')) url = `https://${company?.domain}`;

  return (
    <ErrorBoundary>
      <BoardConfigContextProvider>
        <ProfileContainer>
          <ProfileHeader>
            <ProfileActions>
              {onClose && (
                <CloseButton onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
              )}
              {onExpand && (
                <ExpandButton
                  tooltip="Open full page"
                  onClick={onExpand}
                >
                  <ExpandIcon size={18} />
                </ExpandButton>
              )}
            </ProfileActions>

            {company && (
              <CompanyActionsMenu company={company} />
            )}
          </ProfileHeader>

          <ImageContainer>
            {isLoading ? (
              <ImageSkeleton />
            ) : (
              <StyledCompanyLogo company={company} />
            )}
          </ImageContainer>

          <ProfileInfos>
            {isLoading ? (
              <Skeletons>
                <Skeleton height={16} width={150} />
                <Skeleton height={16} width={250} />
              </Skeletons>
            ) : (company && (
              <>
                <InfosTitle>{company.name}</InfosTitle>
                <InfosSubtitle>
                  <Left>
                    {company?.domain && url ? (
                      <CompanyLink
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {url}
                      </CompanyLink>
                    ) : (
                      <DomainEmpty companyId={companyId} />
                    )}
                    <ExternalIds
                      companyId={companyId}
                    />
                  </Left>
                  <Right>
                    {company.numberOfEmployees && (
                      <BuiltInProperty>
                        <UsersIcon size={18} />
                        {formatEmployees(company.numberOfEmployees)}
                      </BuiltInProperty>
                    )}
                    {company.arr && (
                      <BuiltInProperty>
                        <DollarIcon size={18} />
                        {`${formatArr(company.arr)}\u00A0ARR`}
                      </BuiltInProperty>
                    )}
                  </Right>
                </InfosSubtitle>
                <CustomAttributes companyId={company.id} />
              </>
            ))}
          </ProfileInfos>

          <Summary>
            <SummaryTitle>
              <SummaryIcon />
              Summary
            </SummaryTitle>
            <DocsCountCompany companyId={companyId} />
          </Summary>

          <TabsCompany companyId={companyId} />

          <ExportQuotesFromCompany companyId={companyId} />
          <CompanySummaryModal companyId={companyId} />
        </ProfileContainer>
      </BoardConfigContextProvider>
    </ErrorBoundary>
  );
};

const formatArr = (arr: number) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
}).format(arr);

const formatEmployees = (employees: number) => Intl.NumberFormat('en-US').format(employees);
