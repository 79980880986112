import { ZendeskIcon, HubSpotIcon, IntercomIcon, PipedriveIcon, SnowflakeIcon } from '@cycle-app/ui/icons';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { ExternalIdsContainer } from './Profile.styles';

const builtInKeys = ['zendeskId', 'hubspotId', 'intercomId', 'pipedriveId', 'snowflakeId'] as const;

type BuitInAttributeKeys = typeof builtInKeys[number];

const data: Record<BuitInAttributeKeys, { name?: string; icon?: JSX.Element }> = {
  zendeskId: {
    name: 'Zendesk ID',
    icon: <ZendeskIcon size={14} />,
  },
  hubspotId: {
    name: 'HubSpot ID',
    icon: <HubSpotIcon size={14} />,
  },
  intercomId: {
    name: 'Intercom ID',
    icon: <IntercomIcon size={14} />,
  },
  pipedriveId: {
    name: 'Pipedrive ID',
    icon: <PipedriveIcon size={14} />,
  },
  snowflakeId: {
    name: 'Snowflake ID',
    icon: <SnowflakeIcon size={14} />,
  },
};

type Props = {
  companyId: string;
};

export const ExternalIds = ({ companyId }: Props) => {
  const { company } = useCompany(companyId);
  if (!company) return null;
  const keys = builtInKeys.filter((key) => company[key]);
  if (keys.length === 0) return null;
  return (
    <ExternalIdsContainer>
      {keys.map((key) => {
        const value = company[key];
        const { icon } = data[key];
        if (!value || !icon) return null;
        return (
          <TooltipClickToCopy
            key={key}
            title={data[key].name}
            value={value}
          >
            {props => (
              <button type="button" {...props}>
                {icon}
              </button>
            )}
          </TooltipClickToCopy>
        );
      })}
    </ExternalIdsContainer>
  );
};
