import { DoctypeFragment, StatusCategory } from '@cycle-app/graphql-codegen';

import { SettingsCategory } from './SettingsCategory';
import { Sections } from './SettingsWorkflows.styles';

export const Workflows = ({
  docType, hideBorder = false, hideActions = false,
}: { docType?: DoctypeFragment; hideBorder?: boolean; hideActions?: boolean }) => (
  <Sections $hideBorder={hideBorder}>
    <SettingsCategory
      label="Not started"
      category={StatusCategory.NotStarted}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
    <SettingsCategory
      label="Started"
      category={StatusCategory.Started}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
    <SettingsCategory
      label="Completed"
      category={StatusCategory.Completed}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
    <SettingsCategory
      label="Canceled"
      category={StatusCategory.Canceled}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
  </Sections>
);
