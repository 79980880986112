import { CompanyLogo } from '@cycle-app/ui';
import { plural } from '@cycle-app/utilities';
import { differenceInDays, format, parseISO } from 'date-fns';
import { Fragment } from 'react';

import { openCompanyModal } from '../../hooks/modals/useCompanyModal';
import { AnswerParametersContainer, AnswerParametersTag, AnswerParametersCompanyButton } from './Ask.styles';
import { AskItemParameters } from './Ask.types';

type Props = {
  parameters: AskItemParameters;
};

export const AnswerParameters = ({ parameters }: Props) => {
  const {
    fromDate, toDate, companies, feature,
  } = parameters;
  return (
    <AnswerParametersContainer>
      Ok, so my understanding is that you&apos;re looking for feedback
      {fromDate && toDate && (
        <>
          {' that was captured '}
          <AnswerParametersTag>
            {dates({
              fromDate,
              toDate,
            })}
          </AnswerParametersTag>
        </>
      )}
      {feature && (
        <>
          {' '}
          about
          {' '}
          <AnswerParametersTag>
            {feature}
          </AnswerParametersTag>
        </>
      )}
      {!!companies.length && (
        <>
          {' '}
          for
          {' '}
          {plural('customer', companies.length)}
          {' '}
          {companies.map((companyAnswer, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={i}>
              {
                // eslint-disable-next-line no-nested-ternary
                companies.length > 1
                  ? i === companies.length - 1 ? ' and ' : !!i && ', '
                  : null
              }
              {companyAnswer.company?.id && (
                <AnswerParametersCompanyButton
                  size="S"
                  variant="ternary"
                  onClick={() => {
                    if (companyAnswer.company?.id) {
                      openCompanyModal(companyAnswer.company.id);
                    }
                  }}
                  tooltipProps={{
                    withWrapper: false,
                    content: 'Open profile',
                    placement: 'top',
                  }}
                  iconStart={(
                    <CompanyLogo
                      size="XS"
                      company={{
                        name: companyAnswer.name,
                        logo: companyAnswer.company.logo,
                      }}
                    />
                  )}
                >
                  {companyAnswer.name}
                </AnswerParametersCompanyButton>
              )}
              {!companyAnswer.company?.id && (
                <AnswerParametersTag>
                  {companyAnswer.name}
                </AnswerParametersTag>
              )}
            </Fragment>
          ))}
        </>
      )}
      .
      {' '}
      I&apos;m working on it...
    </AnswerParametersContainer>
  );
};

function dates({
  fromDate, toDate,
}: { fromDate: string; toDate: string }) {
  const now = new Date();
  const fromIso = parseISO(fromDate);
  const toIso = parseISO(toDate);
  let from = '';
  let to = '';
  const fromDiff = differenceInDays(
    fromIso,
    now,
  );
  if (fromDiff === 0) from = 'today';
  else if (fromDiff === 1) from = 'yesterday';
  else from = format(fromIso, 'MMM dd');

  const toDiff = differenceInDays(
    toIso,
    now,
  );
  if (toDiff === 0) to = 'today';
  else if (toDiff === 1) to = 'yesterday';
  else to = format(toDiff, 'MMM dd');

  return from === to
    ? `${from !== 'yesterday' && from !== 'today' ? ' on' : ''}${from}`
    : `between ${from} and ${to}`;
}
