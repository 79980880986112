import { BoardType, StatusCategory } from '@cycle-app/graphql-codegen';
import { useEffect, FC, useState } from 'react';

import BoardContent from 'src/app/Main/Board/BoardContent/BoardContent';
import { MobileViewHeader } from 'src/app/Main/Board/BoardContent/BoardContent.styles';
import { BoardContentSkeleton } from 'src/app/Main/Board/BoardContent/BoardContentSkeleton';
import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal/CreateDocModal';
import { ErrorPage, Size } from 'src/components/ErrorPage';
import { InboxZero } from 'src/components/InboxZero';
import { OfflineState } from 'src/components/OfflineState';
import { ViewBreadcrumb } from 'src/components/ViewBreadcrumb';
import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { matchBoardConfig } from 'src/hoc/matchBoardConfig';
import { useIsOffline, useFeedbackDocType } from 'src/hooks';
import { useInitBoard } from 'src/hooks/boards/useInitBoard';
import { useCurrentInboxView } from 'src/hooks/inbox';
import { useBoardSlug } from 'src/hooks/usePathParams';
import { useIsMobile } from 'src/reactives';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';
import { setLastInboxBoard, setLastView } from 'src/reactives/lastView.reactive';
import { isViewEmpty } from 'src/utils/boardConfig/isViewEmpty.util';

import AnalysesPage from '../../AnalysesPage';
import { ViewContainer } from './Inbox.styles';

export const InboxView: FC<React.PropsWithChildren<unknown>> = matchBoardConfig(({ children }) => {
  const isMobile = useIsMobile();
  const hasError = useBoardConfig(ctx => ctx.hasError);
  const isAnalytics = useBoardConfig(ctx => ctx.isAnalytics);
  const isOffline = useIsOffline();

  if (isOffline && hasError) return <OfflineState />;

  if (hasError) return <ErrorPage />;

  return (
    <ViewContainer id={BULK_SELECTION_AREA_ID} $isScrollable={isAnalytics}>
      {isMobile && (
        <MobileViewHeader>
          <ViewBreadcrumb sectionParent="inbox" />
        </MobileViewHeader>
      )}
      <ViewContent />
      <CreateDocModal />
      {children}
    </ViewContainer>
  );
});

const ViewContent = () => {
  const boardSlug = useBoardSlug();
  const board = useCurrentInboxView();
  const feedback = useFeedbackDocType();
  const hasInboxZero = useHasInboxZero();
  const loading = useBoardConfig(ctx => ctx.loading);
  const notFound = useBoardConfig(ctx => ctx.notFound);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const boardConfigId = boardConfig?.id;
  const [isEmptyStateVisible, setIsEmptyStateVisible] = useState(isViewEmpty(boardConfig));

  // Show the empty state if the board is empty
  useEffect(() => {
    if (hasInboxZero) return;
    if (!isEmptyStateVisible && isViewEmpty(boardConfig)) {
      setIsEmptyStateVisible(true);
    } else if (isEmptyStateVisible && !isViewEmpty(boardConfig)) {
      setIsEmptyStateVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardConfig]);

  // If the user clicks on "New doc" in the empty state,
  // we open the new doc form in the first group
  useEffect(() => {
    if (hasInboxZero) return;
    if (!isEmptyStateVisible && isViewEmpty(boardConfig)) {
      const button = document.querySelector(`#${BULK_SELECTION_AREA_ID} button[data-action="new-doc"]`);
      if (button instanceof HTMLButtonElement) button.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyStateVisible]);

  useInitBoard({
    board,
    boardConfigId,
  });

  useEffect(() => {
    setLastView({ section: 'feedback' });
    setLastInboxBoard({
      boardSlug,
      boardId: board?.id,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardSlug]);

  if (notFound) return <ErrorPage message="Oops, this view no longer exists" size={Size.SMALL} />;

  const viewType = board?.publishedBoardConfig?.viewType;
  if (board?.type === BoardType.Custom && !viewType) return null;

  if (board?.type === BoardType.Analytics) {
    return <AnalysesPage boardId={board.id} dashboardId={board?.dashboardId || null} />;
  }

  if (!boardConfig?.id || loading) return (viewType && <BoardContentSkeleton viewType={viewType} />) || null;

  if (hasInboxZero) {
    return (
      <InboxZero
        onClick={() => {
          setCreateDoc({
            modalVisible: true,
            doctypeId: feedback?.id ?? null,
          });
        }}
      />
    );
  }

  if (isEmptyStateVisible) {
    return (
      <InboxZero
        onClick={() => {
          setIsEmptyStateVisible(false);
        }}
      />
    );
  }

  // Hide My Inbox for super admin users
  const isMyInbox = board?.isBuiltIn && board.name === 'My Inbox';
  if (isMyInbox && !boardConfigId) return null;

  return (viewType && (
    <BoardContent
      viewType={viewType}
      boardConfigId={boardConfigId}
    />
  )) || null;
};

const useHasInboxZero = (): boolean => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  if (boardConfig?.docQuery.__typename !== 'BoardQueryWithGroupBy') return false;
  const groups = boardConfig.docQuery.docGroups.edges;
  if (groups.length !== 1) return false;
  const group = groups[0]?.node;
  if (group?.propertyValue?.__typename !== 'Status') return false;
  if (group.propertyValue.category !== StatusCategory.NotStarted) return false;
  return group.docs.edges.length === 0 && !group.docs.pageInfo.hasNextPage;
};
