import { DocAttribute, DocBaseWithDocSourceFragment } from '@cycle-app/graphql-codegen';
import { CustomerAvatar } from '@cycle-app/ui';
import { InboxIcon } from '@cycle-app/ui/icons';
import { nodeToArray, getHighlightHash } from '@cycle-app/utilities';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocSource } from 'src/components/DocSource';
import { DocTagProperties } from 'src/components/DocTagProperties';
import { InsightCardOptions } from 'src/components/InsightCardOptions';
import { navigateToDocFullPage } from 'src/hooks';
import { useInsightCardEditQuoteProps } from 'src/hooks/insight/useInsightCardEditQuoteProps';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';

import { StyledInsightCard, QuoteCardOptions, CustomerContainer, CustomerName, FeedbackAction } from './Profile.styles';

export type Props = {
  doc: DocBaseWithDocSourceFragment;
};

export const QuoteCard = ({ doc }: Props) => {
  const docType = useGetDocType(doc.doctype.id);
  const feedbackDoc = doc.docSource?.doc;

  const {
    isClickOnEditForm,
    contextEl,
  } = useInsightCardEditQuoteProps({
    docTarget: doc.docSource,
  });

  return (
    <StyledInsightCard
      blockId={doc.docSource?.blockId}
      context={contextEl}
      contextText={doc.docSource?.content ?? ''}
      options={(
        <QuoteCardOptions>
          <DocTagProperties properties={nodeToArray(doc.attributes) as DocAttribute[]} />
          <QuoteCardOptions>
            <DocSource doctypeId={doc.doctype.id} docId={doc.id} source={doc.source} />
            {!!doc.assignee?.id && docType && (
              <DocAssignee
                assignee={doc.assignee}
                showLabel={false}
                tooltipPlacement="top"
                isDisabled={!!doc.docSource}
                isRemovable={false}
                layer={Layer.DropdownModalZ3}
                dropdownPlacement="bottom-end"
                docId={doc.id}
                docTypeType={docType.type}
                docTypeName={docType.name}
                context="doc-item"
              />
            )}

            {feedbackDoc && (
              <FeedbackAction
                tooltip="Click to open"
                tooltpTitle={feedbackDoc.title}
                tooltipPlacement="top"
                onClick={(e) => {
                  navigateToDocFullPage(feedbackDoc, undefined, e.metaKey);
                }}
              >
                <InboxIcon />
              </FeedbackAction>
            )}

            <InsightCardOptions
              layer={Layer.DropdownModalZ3}
              blockId={doc.docSource?.blockId}
              doc={doc}
              editParent={!doc.parent}
            />
          </QuoteCardOptions>
        </QuoteCardOptions>
      )}
      onClick={e => {
        if (isClickOnEditForm(e)) return;
        const params = doc.docSource?.doc ? {
          title: doc.docSource.doc.title,
          id: doc.docSource.doc.id,
          hash: getHighlightHash({
            docId: doc.id,
            blockId: doc.docSource?.blockId,
          }),
        } : {
          title: doc.title,
          id: doc.id,
        };
        navigateToDocFullPage(params, undefined, e.metaKey);
      }}
      title={doc.title}
      parentSlot={(
        <DocCompanyCustomer
          doc={doc}
          showCompanyName
          isDisabled
          layer={Layer.DropdownModalZ3}
        >
          {doc.customer && (
            <CustomerContainer>
              <CustomerAvatar
                customer={doc.customer}
                size="XS"
                hideCompany
              />
              <CustomerName>{doc.customer.displayName}</CustomerName>
              {doc.customer.company?.name && (
                <>
                  <span>·</span>
                  <span>{doc.customer.company.name}</span>
                </>
              )}
            </CustomerContainer>
          )}
        </DocCompanyCustomer>
      )}
    />
  );
};
