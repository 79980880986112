/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react';

if (import.meta.env.PROD && import.meta.env.VITE_USE_WDYR === 'true') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    // trackAllPureComponents: true,
    // logOnDifferentValues: true,
  });
}
