import { AiOverlay } from 'src/components/AiOverlay';
import { AiStateModal } from 'src/components/AiStateTag/AiStateModal';
import { ProfileModal } from 'src/components/CustomerCompanyProfile';
import { CompanyProfileModal } from 'src/components/CustomerProfile/CompanyProfileModal';
import { CustomerProfileModal } from 'src/components/CustomerProfile/CustomerProfileModal';
import { LinearModal } from 'src/components/DocLinear/LinearModal';
import { DocPreviewModal } from 'src/components/DocPreviewModal/DocPreviewModal';
import { EnableRoadmapModal } from 'src/components/EnableRoadmapModal';
import { FeedbackProductTour } from 'src/components/FeedbackProductTour';
import { InsightProductTour } from 'src/components/InsightProductTour';
import { LearningCenterModal } from 'src/components/LearningCenterModal';
import { LimitationModal } from 'src/components/LimitationModal';
import { NewVersionToaster } from 'src/components/NewVersionToaster';
import { OfflineWarning } from 'src/components/OfflineWarning';
import { OnboardingSlack } from 'src/components/OnboardingSlack';
import { SlackTourModal } from 'src/components/ProductTour/SlackTourModal';
import { ReleasesProductTour } from 'src/components/ReleasesProductTour';
import { RoadmapProductTour } from 'src/components/RoadmapProductTour';
import { RoadmapTemplatesModal } from 'src/components/RoadmapTemplatesModal';
import SettingsModal from 'src/components/SettingsModal/SettingsModal';
import { SlackSettingsModal } from 'src/components/SlackSettingsModal';
import { SourcesModal } from 'src/components/SourcesModal';
import { StateToasters } from 'src/components/StateToasters';
import { ZoomImage } from 'src/components/ZoomImage/ZoomImage';
import { useFeatureFlag } from 'src/hooks';
import { isDevEnv } from 'src/utils/env.util';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { DashboardUpdateModal } from '../DashboardUpdateModal';
import { DocAutomationNotify } from '../DocAutomationNotify';
import { IntegrationSyncModal } from '../IntegrationSyncModal';
import { LinearImportModal } from '../LinearImportModal';
import { MettingsModalInstalled } from '../MettingsModalInstalled';
import { UpgradeModal } from '../UpgradeModal';

export const AppGlobalDialogs = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { isEnabled: isEditorAiEnabled } = useFeatureFlag('editor-ai');
  return (
    <>
      <DocAutomationNotify />
      <ReleasesProductTour />
      <FeedbackProductTour />
      <InsightProductTour />
      <RoadmapProductTour />
      <DocPreviewModal />
      <LimitationModal />
      <LearningCenterModal />
      <SlackTourModal />
      <OnboardingSlack />
      <StateToasters>
        <OfflineWarning />
        {!isDevEnv() && <NewVersionToaster />}
      </StateToasters>
      <SlackSettingsModal />
      <SourcesModal />
      <RoadmapTemplatesModal />
      {isEditorAiEnabled && <AiOverlay />}
      <IntegrationSyncModal />
      <ZoomImage />
      <LinearImportModal />
      <LinearModal />
      <MettingsModalInstalled />
      <SettingsModal />
      <AiStateModal />
      <EnableRoadmapModal />
      <UpgradeModal productId={productId} />
      <DashboardUpdateModal />
      {import.meta.env.VITE_CUSTOMER_PROFILE_V2 === 'on' ? (
        <ProfileModal />
      ) : (
        <>
          <CompanyProfileModal />
          <CustomerProfileModal />
        </>
      )}
    </>
  );
};
