import { CustomerWithDoctypeCountFragment } from '@cycle-app/graphql-codegen';
import {
  RowsSkeleton,
  THead,
  TBody,
  Tr,
  TextHighlighter,
  CellContent,
  Tooltip,
  InfiniteScroll,
} from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';
import {
  useMemo, FC, useLayoutEffect, useRef, useState, ReactNode,
} from 'react';

import { copyToClipboard } from 'src/utils/clipboard.utils';

import { CustomerLinkedProfiles } from '../CustomerProfile/CustomerLinkedProfiles';
import { CustomerActionsMenu } from './CustomerActionsMenu';
import {
  StyledTable,
  TdActions,
  StyledTr,
  StyledTh,
  StyledTd,
  StyledShyScrollbar,
  DocCountCell,
  StyledThActions,
} from './Customers.styles';
import { CustomerTableCompanyCell } from './CustomerTableCompanyCell';
import { CustomerTableNameCell } from './CustomerTableNameCell';

const NAME_MAX_WIDTH = 500;

type HiddenData = {
  header?: boolean;
  action?: boolean;
  profiles?: boolean;
  companies?: boolean;
  docs?: boolean;
};

type CustomersListPeopleProps = {
  customers: CustomerWithDoctypeCountFragment[];
  searchText: string;
  loadMore?: VoidFunction;
  className?: string;
  onCustomerClick?: (id: string) => void;
  onCompanyClick?: (id: string) => void;
  isLoading?: boolean;
  hasNextPage?: boolean;
  isFullPage?: boolean;
  shyScrollDisabled?: boolean;
  scrollableElement?: HTMLElement | null;
  nameCellSize?: number;
  emailCellSize?: number;
  actionsCellSize?: number;
  hideLastDiscovery?: boolean;
  hiddenData?: HiddenData;
  isCompact?: boolean;
  /**
   * Merge Email
   * Will hide the email column and add email to the name value as:
   * -> userName (userEmail)
   * -> John Doe (john.doe@email.com)
   */
  mergeEmail?: boolean;
  footer?: ReactNode;
};

export const CustomersListPeople: FC<React.PropsWithChildren<CustomersListPeopleProps>> = ({
  shyScrollDisabled,
  scrollableElement,
  customers,
  searchText,
  className,
  onCustomerClick,
  onCompanyClick,
  isLoading,
  loadMore,
  hasNextPage,
  isFullPage = true,
  nameCellSize = 300,
  emailCellSize = 300,
  actionsCellSize,
  hiddenData = {},
  isCompact = false,
  mergeEmail = false,
  footer,
}) => {
  const showHeader = !hiddenData.header;
  const showActions = !hiddenData.action;
  const showCompanies = !hiddenData.companies;
  const showDocs = !hiddenData.docs;
  const showProfiles = !hiddenData.profiles;

  const sizeCell = isCompact ? 180 : nameCellSize;
  const sizeCellTablet = isCompact ? 180 : 250;

  // Compute max width of first column
  const tableRef = useRef<HTMLTableElement>(null);
  const [nameMaxWidth, setNameMaxWidth] = useState(sizeCell);
  useLayoutEffect(() => {
    if (tableRef.current) {
      const contents = [...tableRef.current.querySelectorAll('td:first-of-type > span')];
      if (contents.length > 0) {
        const widths = contents.map(el => el.getBoundingClientRect().width);
        setNameMaxWidth(Math.min(NAME_MAX_WIDTH, 20 + Math.max(...widths)));
      }
    }
  }, [customers]);

  const cols = useMemo(() => {
    let count = 1;
    if (showCompanies) count += 1;
    if (!mergeEmail) count += 1;
    if (showDocs) count += 1;
    if (showProfiles) count += 1;
    if (showActions) count += 1;
    return count;
  }, [mergeEmail, showActions, showCompanies, showDocs, showProfiles]);

  return (
    <StyledShyScrollbar disable={shyScrollDisabled}>
      <InfiniteScroll
        scrollableElement={scrollableElement}
        isLoading={!!isLoading}
        hasMoreData={!!hasNextPage}
        loadMore={loadMore}
      >
        <StyledTable className={className} $isPrimary={isFullPage} ref={tableRef}>
          {showHeader && (
            <THead>
              <Tr>
                <StyledTh $size={nameMaxWidth} $sizeTablet={sizeCellTablet} $sizeMobile={sizeCellTablet}>
                  Name
                </StyledTh>
                {showCompanies && <StyledTh $size={180}>Company</StyledTh>}
                {!mergeEmail && <StyledTh $size={emailCellSize}>Email</StyledTh>}
                {showDocs && <StyledTh $size={90}>Quotes</StyledTh>}
                {showProfiles && <StyledTh $size={120}>Linked profiles</StyledTh>}
                {showActions && <StyledThActions $size={actionsCellSize} />}
              </Tr>
            </THead>
          )}
          <TBody>
            {customers.map(customer => (
              <StyledTr key={customer.id} $isHoverable>
                <CustomerNameCell
                  sizeCell={nameMaxWidth}
                  customer={customer}
                  onCustomerClick={onCustomerClick}
                  searchText={searchText}
                  isCompact={isCompact}
                  mergeEmail={mergeEmail}
                />
                {showCompanies && (
                  <StyledTd>
                    {customer.company?.id && customer.company.name && (
                      <CustomerTableCompanyCell
                        name={customer.company.name}
                        company={customer.company}
                        {...onCompanyClick && {
                          onClick: () => onCompanyClick(customer.company?.id ?? ''),
                        }}
                      />
                    )}
                  </StyledTd>
                )}
                {!mergeEmail && (
                  <StyledTd>
                    {customer.email && (
                      <CellContent $isClickbable $limitSize>
                        <Tooltip content="Copy email" title={customer.email} placement="top" withPortal>
                          <TextHighlighter
                            onClick={() => customer.email && copyToClipboard({
                              text: customer.email,
                              notification: 'Email copied to clipboard',
                            })}
                            className="highlight"
                            searchWords={[searchText]}
                            textToHighlight={customer.email}
                          />
                        </Tooltip>
                      </CellContent>
                    )}
                  </StyledTd>
                )}
                {showDocs && (
                  <StyledTd>
                    <DocCountCell>
                      <QuoteIcon size={14} />
                      {customer.nbInsights}
                    </DocCountCell>
                  </StyledTd>
                )}
                {showProfiles && (
                  <StyledTd>
                    <CustomerLinkedProfiles customer={customer} />
                  </StyledTd>
                )}
                <TdActions>
                  {showActions && (
                    <CustomerActionsMenu customer={customers.find(c => c.id === customer.id)} />
                  )}
                </TdActions>
              </StyledTr>
            ))}
            {isLoading && <RowsSkeleton rows={3} cols={cols} />}
            {footer && <StyledTr><StyledTd>{footer}</StyledTd></StyledTr>}
          </TBody>
        </StyledTable>
      </InfiniteScroll>
    </StyledShyScrollbar>
  );
};

type CustomerNameCellProps = {
  sizeCell: number;
  customer: CustomerWithDoctypeCountFragment;
  onCustomerClick: CustomersListPeopleProps['onCustomerClick'];
  searchText: string;
  isCompact: boolean;
  mergeEmail: boolean;
};

const CustomerNameCell: FC<React.PropsWithChildren<CustomerNameCellProps>> = ({
  sizeCell,
  customer,
  onCustomerClick,
  searchText,
  isCompact,
  mergeEmail,
}) => {
  const name = customer.name || '';
  // eslint-disable-next-line no-nested-ternary
  const email = customer.email
    ? name
      ? `(${customer.email})`
      : customer.email
    : '';
  const labelText = mergeEmail && customer.email
    ? `${name.length ? `${name} ` : ''}${email}`
    : name;
  const label = searchText
    ? <TextHighlighter className="highlight" searchWords={[searchText]} textToHighlight={labelText} />
    : labelText;

  const nameRef = useRef<HTMLSpanElement>(null);

  return (
    <StyledTd style={{ maxWidth: sizeCell }}>
      <span ref={nameRef}>
        <CustomerTableNameCell
          customer={customer}
          name={label}
          isCompact={isCompact}
          {...onCustomerClick && {
            onClick: () => onCustomerClick(customer.id),
          }}
        />
      </span>
    </StyledTd>
  );
};
