import { GetImageTextDocument, GetTranscriptDocument, GetDocumentTextDocument } from '@cycle-app/graphql-codegen';
import { ERROR_CODE } from '@cycle-app/utilities';
import type { FileType } from '@cycle-app/utilities';
import { Content, generateJSON, NodeViewRendererProps } from '@tiptap/core';

import { ErrorMessage } from 'src/constants/errors.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useSafeMutation } from 'src/hooks';
import { getPermission, hasEditorNodeNextAction, removeEditorNodeNextAction, setLimitationsModal } from 'src/reactives';
import { addErrorToaster } from 'src/utils/errorToasters.utils';
import { isPdfLink, isDocumentTranscribable } from 'src/utils/files.util';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { useUserLanguage } from '../user/useUserLanguage';

type GetTranscriptParams = {
  src: string;
  type: FileType;
  insertContent?: boolean;
};

const formatContent = (str: string) => {
  return str.split('\n\n')
    .map(p => `<p>${p.replaceAll('\n', '<br />').trim()}</p>`)
    .join('')
    .trim();
};

export const useTranscript = ({
  node, getPos, dataId,
}: Pick<NodeViewRendererProps, 'node' | 'getPos'> & { dataId: string | undefined }) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const { language } = useUserLanguage();
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [getImageTranscript, {
    loading: isImageLoading, error: imageError,
  }] = useSafeMutation(GetImageTextDocument, {
    onError: () => {
      addErrorToaster({ message: ErrorMessage.TRANSCRIPT_FAILED });
    },
  });

  const [getDocumentTranscript, {
    loading: isDocumentLoading, error: documentError,
  }] = useSafeMutation(GetDocumentTextDocument, {
    onError: () => {
      addErrorToaster({ message: ErrorMessage.TRANSCRIPT_FAILED });
    },
  });

  const [getVideoTranscript, {
    loading: isVideoLoading, error: videoError,
  }] = useSafeMutation(GetTranscriptDocument, {
    onError: (error) => {
      if (error.message === ERROR_CODE.AUDIO_NOT_FOUND) {
        addErrorToaster({ message: ErrorMessage.AUDIO_NOT_FOUND });
      } else {
        addErrorToaster({ message: ErrorMessage.TRANSCRIPT_FAILED });
      }
    },
  });

  const insertTranscript = (content: Content) => {
    if (!node || typeof getPos !== 'function') return;
    const from = getPos();
    if (Number.isNaN(from)) return;
    const to = from + node.nodeSize;
    editor.chain().focus().insertContentAt(to, content).run();
  };

  // src is an uploaded url. Ex: https://res.cloudinary.com/do44szjts/image/upload/v1694078766/file.ext
  const getTranscript = async ({
    src, type, insertContent,
  }: GetTranscriptParams) => {
    if (!getPermission().canTranscribe) {
      setLimitationsModal({ action: 'TRANSCRIPT' });
      return null;
    }

    let content: null | Content = null;
    const fileName = src.split('/').pop();
    if (!fileName) return null;

    if (type === 'image' || isPdfLink(fileName)) {
      const result = await getImageTranscript({
        variables: {
          fileName,
        },
      });
      if (result.data?.extractTextFromImage) {
        if (result.data.extractTextFromImage.length) {
          content = generateJSON(formatContent(result.data.extractTextFromImage), editor.extensionManager.extensions);
        } else {
          addErrorToaster({ message: ErrorMessage.TRANSCRIPT_FAILED });
        }
      }
    } else if (isDocumentTranscribable(fileName)) {
      const result = await getDocumentTranscript({
        variables: {
          fileName,
        },
      });
      if (result.data?.extractTextFromDocument) {
        if (result.data.extractTextFromDocument.length) {
          content = generateJSON(formatContent(result.data.extractTextFromDocument), editor.extensionManager.extensions);
        } else {
          addErrorToaster({ message: ErrorMessage.TRANSCRIPT_FAILED });
        }
      }
    } else if (type === 'video' || type === 'audio') {
      const result = await getVideoTranscript({
        variables: {
          fileName,
          language,
          productId,
        },
      });
      if (result.data?.transcript) {
        if (result.data.transcript.length) {
          content = generateJSON(formatContent(result.data.transcript), editor.extensionManager.extensions);
        } else {
          addErrorToaster({ message: ErrorMessage.TRANSCRIPT_FAILED });
        }
      }
    } else {
      addErrorToaster({ message: ErrorMessage.FILE_NOT_SUPPORTED });
    }
    if (dataId && hasEditorNodeNextAction(dataId) === 'transcript') {
      removeEditorNodeNextAction(dataId);
    }
    if (content && insertContent) {
      insertTranscript(content);
    }
    return content;
  };

  return {
    getTranscript,
    isLoading: isImageLoading || isDocumentLoading || isVideoLoading,
    error: imageError || documentError || videoError,
    insertTranscript,
  };
};
