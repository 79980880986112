import { useFeatureFlag } from 'src/hooks';
import { useAskSubscription } from 'src/hooks/api/useAskSubscription';
import { closeAskPanel, useGetAskPanel } from 'src/reactives/ask.reactive';

import { BoardConfigContextProvider } from '../../contexts/boardConfigContext';
import { StyledDropdown } from './Ask.styles';
import { AskPanel } from './AskPanel';

export const Ask = () => {
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  const isAskOpen = useGetAskPanel().isOpen;
  useAskSubscription();

  if (!isAskEnabled) return null;

  // BoardConfigContextProvider is needed for the docs
  return (
    <BoardConfigContextProvider>
      <StyledDropdown
        withPortal
        placement="top-start"
        visible={isAskOpen}
        hide={closeAskPanel}
        offset={[30, 30]}
        getReferenceClientRect={() => new DOMRect(0, window.innerHeight, 0, 0)}
        width={596}
        content={<AskPanel />}
      />
    </BoardConfigContextProvider>
  );
};
