import { typo, Button, ActionButton, Input } from '@cycle-app/ui';
import styled from 'styled-components';

const NAME_GAP = 6;
const EDIT_BTN_SIZE = 20;

export const Name = styled.div`
  display: flex;
  gap: ${NAME_GAP}px;
  align-items: center;
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};

  /* Horizontally center the name when the edit button is hidden */
  position: relative;
  left: ${(EDIT_BTN_SIZE + NAME_GAP) / 2}px;
`;

export const EditButton = styled(ActionButton)`
  width: ${EDIT_BTN_SIZE}px;
  height: ${EDIT_BTN_SIZE}px;
  border-radius: 5px;

  color: ${p => p.theme.colors.text.disabled};
  :hover, :focus, :active {
    --color: ${p => p.theme.colors.text.secondary};
  }

  opacity: 0;
  ${Name}:hover & {
    opacity: 1;
  }
`;

export const AddButton = styled(Button).attrs({
  size: 'M',
  variant: 'light',
})`
  height: 24px;
  padding: 0 4px;
  gap: 4px;
  --radius: 4px;
  --color: ${p => p.theme.colors.text.disabled};
  --bg: transparent;
  :hover, :focus, :active {
    --color: ${p => p.theme.colors.text.secondary};
  }
`;

export const DomainInput = styled(Input)`
  input {
    min-width: 200px;
    height: 30px;
    margin: -3px 0;
    padding: 0 6px;
    border-radius: 6px;
  }
`;
