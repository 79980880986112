import { CompanyFullFragment } from '@cycle-app/graphql-codegen';
import {
  THead,
  TBody,
  Tr,
  RowsSkeleton,
  TextHighlighter,
  InfiniteScroll,
  Tooltip,
} from '@cycle-app/ui';
import {
  ZendeskIcon,
  HubSpotIcon,
  IntercomIcon,
  PipedriveIcon,
  SnowflakeIcon,
} from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { useProduct } from 'src/hooks';

import { CompanyActionsMenu } from './CompanyActionsMenu';
import {
  StyledTable,
  TdActions,
  StyledTr,
  StyledShyScrollbar,
  StyledTd,
  StyledTh,
  StyledThActions,
  LinkedProfiles,
  LinkedProfileButton,
} from './CustomersListCompanies.styles';
import { CustomerTableCompanyCell } from './CustomerTableCompanyCell';

interface Props {
  companies: CompanyFullFragment[];
  className?: string;
  onCustomerClick?: (id: string) => void;
  onCompanyClick?: (id: string) => void;
  isLoading?: boolean;
  hasNextPage?: boolean;
  loadMore: VoidFunction;
  searchText: string;
  isFullPage?: boolean;
}

const externalIdsKeys = ['zendeskId', 'hubspotId', 'intercomId', 'pipedriveId', 'snowflakeId'] as const;

type ExternalIdsKeys = typeof externalIdsKeys[number];

const externalIdsData: Record<ExternalIdsKeys, { name: string; icon?: JSX.Element }> = {
  zendeskId: {
    name: 'Zendesk ID',
    icon: <ZendeskIcon size={16} />,
  },
  hubspotId: {
    name: 'HubSpot ID',
    icon: <HubSpotIcon size={16} />,
  },
  intercomId: {
    name: 'Intercom ID',
    icon: <IntercomIcon size={16} />,
  },
  pipedriveId: {
    name: 'Pipedrive ID',
    icon: <PipedriveIcon size={16} />,
  },
  snowflakeId: {
    name: 'Snowflake ID',
    icon: <SnowflakeIcon size={16} />,
  },
};

type Row = Record<string, string | number | boolean>;

export const CustomerListCompanies: FC<React.PropsWithChildren<Props>> = ({
  className, companies, onCompanyClick, isLoading, hasNextPage, loadMore, searchText, isFullPage = true,
}) => {
  const { product } = useProduct();
  const customAttributesColumns = nodeToArray(product?.companyAttributeDefinitions);

  const customAttributesRows = useMemo(() => {
    const rows: Record<string, Row> = {};

    for (const company of companies) {
      const row: Row = {};

      for (const edge of company.attributes.edges) {
        const {
          definition, value,
        } = edge.node;

        if (value?.__typename === 'AttributeTextValue') {
          row[definition.id] = value.textValue;
        }
        if (value?.__typename === 'AttributeNumberValue') {
          row[definition.id] = value.numberValue;
        }
        if (value?.__typename === 'AttributeCheckboxValue') {
          row[definition.id] = value.checkboxValue;
        }
      }
      rows[company.id] = row;
    }

    return rows;
  }, [companies]);

  return (
    <StyledShyScrollbar>
      <InfiniteScroll
        id="companies"
        isLoading={!!isLoading}
        hasMoreData={!!hasNextPage}
        loadMore={loadMore}
      >
        <StyledTable className={className} $isPrimary={isFullPage}>
          <THead>
            <Tr>
              <StyledTh>Name</StyledTh>
              <StyledTh>People</StyledTh>

              <StyledTh>
                ARR
              </StyledTh>

              <StyledTh>
                Employees
              </StyledTh>

              <StyledTh>
                Linked profiles
              </StyledTh>

              <StyledTh>
                Custom ID
              </StyledTh>

              {customAttributesColumns.map(column => {
                if (!('id' in column)) return null;
                return ((
                  <StyledTh key={column.id}>
                    <Tooltip
                      content={column.description}
                      placement="top"
                      withPortal
                      withWrapper={false}
                      disabled={!column.description}
                    >
                      {column.name}
                    </Tooltip>
                  </StyledTh>
                ));
              })}

              <StyledThActions $size={50} />
            </Tr>
          </THead>

          <TBody>
            {companies.map(company => (
              <StyledTr key={company.id}>
                <StyledTd>
                  <CustomerTableCompanyCell
                    name={<TextHighlighter className="highlight" searchWords={[searchText]} textToHighlight={company.name ?? ''} />}
                    company={company}
                    onClick={() => onCompanyClick?.(company?.id ?? '')}
                  />
                </StyledTd>

                <StyledTd>{company.countCustomers ?? 0}</StyledTd>

                <StyledTd $type="number">
                  {formatArr(company.arr)}
                </StyledTd>

                <StyledTd $type="number">
                  {formatEmployees(company.numberOfEmployees)}
                </StyledTd>

                <StyledTd>
                  <LinkedProfiles>
                    {externalIdsKeys.map(key => {
                      const data = externalIdsData[key];
                      const value = company[key];
                      if (!value || !data) return null;
                      return (
                        <TooltipClickToCopy
                          key={key}
                          value={value}
                          notification={`${data.name} successfully copied to clipboard`}
                          title={(
                            <div>
                              <div>{data.name}</div>
                              <div>{value}</div>
                            </div>
                          )}
                        >
                          {props => (
                            <LinkedProfileButton {...props}>
                              {data.icon}
                            </LinkedProfileButton>
                          )}
                        </TooltipClickToCopy>
                      );
                    })}
                  </LinkedProfiles>
                </StyledTd>

                <StyledTd $type="text">
                  {company.customId}
                </StyledTd>

                {customAttributesColumns.map(column => {
                  if (!('id' in column)) return null;
                  const value = (() => {
                    const v = customAttributesRows[company.id]?.[column.id];
                    if (v === true) return column.name;
                    if (v === false) return null;
                    return v;
                  })();
                  return (
                    <StyledTd
                      key={column.id}
                      $type={typeof value === 'number' ? 'number' : 'text'}
                    >
                      {value}
                    </StyledTd>
                  );
                })}

                <TdActions>
                  {!company.isDefault && <CompanyActionsMenu company={company} />}
                </TdActions>
              </StyledTr>
            ))}
            {isLoading && <RowsSkeleton rows={5} cols={3 + externalIdsKeys.length + customAttributesColumns.length} />}
          </TBody>
        </StyledTable>
      </InfiniteScroll>
    </StyledShyScrollbar>
  );
};

const formatArr = (value: number | null | undefined) => {
  if (value == null) return null;
  if (!Number.isFinite(value)) return value;
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value);
};

const formatEmployees = (value: number | null | undefined) => {
  if (value == null) return null;
  if (!Number.isFinite(value)) return value;
  return Intl.NumberFormat('en-US').format(value);
};
